import {
  FormatBold,
  FormatClear,
  FormatColorText,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatPaint,
  FormatUnderlined,
  MoreHoriz,
  Redo,
  Remove,
  StrikethroughS,
  Subscript,
  Superscript,
  Undo,
} from "@mui/icons-material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import { BubbleMenu } from "@tiptap/react";
import React, { useCallback, useEffect, useState } from "react";

import Select from "react-select";
import LinkIcon from "@mui/icons-material/Link";
import "./TextEditorComponent.css";
import Add from "@mui/icons-material/Add";
import { StylesConfig } from "react-select";
import ColorPicker from "./ColorPicker/ColorPicker";
import { useAppSelector } from "../../../../../store/hooks";
import { getPageLocaleUrl } from "../../../../../components/_default/utilities/helpers";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";

export const DefaultReactSelectStyle: StylesConfig<any, false> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  clearIndicator: (base) => ({
    ...base,
    padding: "0px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "0px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "transparent",
    border: 0,
    minHeight: "inherit",
    borderRadius: 0,
    fontWeight: "normal",
    boxShadow: "none",
    fontFamily: "sans-serif",
    "&:hover": {
      borderColor: state.isFocused ? "#fff" : "#9b9b9b",
    },
  }),
  container: (base, state) => ({
    ...base,
    borderBottom: state.isFocused ? "2px solid #fff" : "1px solid #9b9b9b",
    marginBottom: state.isFocused ? "0px" : "1px",
  }),
  menu: (base, state) => ({
    ...base,
    backgroundColor: "hsl(0deg 0% 28%)",
    width: "max-content",
    minWidth: "100%",
    fontSize: "12px",
    ".MuiSvgIcon-root": {
      fontSize: "15px",
    },
  }),
  option: (base, state) => ({
    ...base,
    padding: "4px 8px",
    color: "#fff",
    fontWeight: "normal",
    fontFamily: "sans-serif",
    backgroundColor: state.isSelected ? "#333" : "inherit", // Default background color changed here
    "&:active": {
      backgroundColor: state.isSelected ? "#333" : "inherit",
    },
    "&:hover": {
      backgroundColor: state.isSelected ? "#333" : "hsl(0deg 1% 19%)", // Change hover background color
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "#fff",
  }),
  input: (base) => ({
    ...base,
  }),
};

export const TextEditorSelectStyle: StylesConfig<any, false> = {
  ...DefaultReactSelectStyle,
  menuList: (styles, data) => {
    return {
      ...styles,
      overflowX: "hidden",
      fontSize: "11px",
    };
  },
  container: (styles, data) => {
    return {
      ...styles,
      fontSize: "11px",
      height: "20px",
    };
  },
  singleValue: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.singleValue!(styles, data),
      overflow: "visible",
      fontSize: "11px",
    };
  },
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    padding: "1px 2px",
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "normal",
    fontFamily: "sans-serif",
    backgroundColor: isSelected ? "#333" : "inherit", // Default background color changed here
    "&:active": {
      backgroundColor: isSelected ? "#333" : "inherit",
    },
    "&:hover": {
      backgroundColor: isSelected ? "#333" : "hsl(0deg 1% 19%)", // Change hover background color
    },
  }),
  control: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.control!(styles, data),
      minHeight: "inherit",
      flexWrap: "nowrap",
    };
  },
  valueContainer: (styles, data) => {
    return {
      ...styles,
      padding: "0px",
      minWidth: "inherit",
      alignSelf: "flex-start",
      overflow: "hidden",
      fontSize: "11px",
    };
  },
  menu: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.menu!(styles, data),
      marginTop: "2px",
    };
  },
  dropdownIndicator: (styles, data) => {
    return {
      ...styles,
      height: "100%",
      padding: 0,
      alignSelf: "flex-start",
      alignItems: "center",
      width: "12px",
      color: "#fff",
      svg: {
        height: "12px",
      },
    };
  },
};

export default function TextEditorComponent({ id }) {
  const editor = useAppSelector((state) => state.builder.editor.editor);
  const [fontSize, setFontSize] = useState<number>(16);
  const [fontSizeUnit, setFontSizeUnit] = useState("px");
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [hoverColorPickerOpen, setHoverColorPickerOpen] = useState(false);
  const [linkMenuOpen, setLinkMenuOpen] = useState(false);
  const [activeLinkMenu, setActiveLinkMenu] = useState("");
  const [url, setUrl] = useState(
    editor.getAttributes("link").href ? editor.getAttributes("link").href : ""
  );
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  );

  const [highlightColorPicker, setHighlightColorPicker] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const typographies = useAppSelector((state) => state.builder.typographies);

  const [targetBlank, setTargetBlank] = useState(
    editor.isActive("link", { target: "_blank" }) ? true : false
  );

  const fonts = useAppSelector((state) => state.builder.font_options);
  const languages = useAppSelector((state) => state.builder.languageSettings);
  const webpages = useAppSelector((state) => state.builder.pages.list);

  let types = [
    { label: "Web in", value: "Web" },
    { label: "Web out", value: "Web_out" },
    { label: "None", value: "None" },
    { label: "Anchor", value: "Anchor" },
    { label: "Phone", value: "Phone" },
    { label: "Email", value: "Email" },
  ];

  let targetTypes = [
    {
      label: "Open new tab",
      value: "_blank",
    },
    {
      label: "Open same tab",
      value: "_self",
    },
  ];

  useEffect(() => {
    if (editor && editor.getAttributes("link").href) {
    }
  }, [url]);

  useEffect(() => {
    if (editor && editor.getAttributes("textStyle").fontSize) {
      const value = editor.getAttributes("textStyle").fontSize;

      const fontSizeNumber = parseFloat(value);
      const fontSizeUnit = value.match(/[a-zA-Z]+$/)[0];

      setFontSize(fontSizeNumber);
      setFontSizeUnit(fontSizeUnit);
    } else if (editor && !editor.getAttributes("textStyle").fontSize) {
      setFontSize(16);
      setFontSizeUnit("px");
    }
  }, [editor.getAttributes("textStyle").fontSize]);

  useEffect(() => {
    if (editor && editor.getAttributes("link").href) {
      setUrl(editor.getAttributes("link").href);
    } else {
      setUrl("");
    }
  }, [editor.getAttributes("link").href, linkMenuOpen]);

  const fontOptions: any = [{ value: "Arial", label: "Arial" }];

  const [type, setType] = useState("");

  useEffect(() => {
    if (url) {
      if (url.startsWith("/")) {
        setType("Web");
      } else if (url.startsWith("#")) {
        setType("Anchor");
      } else if (url.startsWith("tel:")) {
        setType("Phone");
      } else if (url.startsWith("mailto:")) {
        setType("Email");
      } else if (url.startsWith("https://") || url.startsWith("http://")) {
        setType("Web_out");
      } else {
        setType("None");
      }
    } else {
      setType("None");
    }

    setTargetBlank(
      editor.isActive("link", { target: "_blank" }) ? true : false
    );
  }, [url, linkMenuOpen]);

  // useEffect(() => {
  //   if (editor && editor.getAttributes("link")) {
  //     setTargetBlank(
  //       editor.getAttributes("link").target === "_blank" ? true : false
  //     );
  //   }
  // }, [editor.getAttributes("link")]);

  const fontSizeUnits = [
    { value: "px", label: "px" },
    { value: "em", label: "em" },
    { value: "rem", label: "rem" },
  ];

  if (fonts) {
    fonts.forEach((font: any) => {
      fontOptions.push({ value: font.name, label: font.name });
    });
  }

  const groupedOptions: any[] = [];

  languages.activeLanguages.map((language) => {
    const filteredWebpages = webpages.filter((page) => {
      return page.language?.language_id === language.language.id;
    });

    groupedOptions.push({
      label: language.language.name,
      options: filteredWebpages
        .map((page) => {
          return {
            label: page.name,
            value: getPageLocaleUrl(page, languages.activeLanguages),
            pageId: page.id,
          };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    });
  });

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    margin: "0px",
  };

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span style={{ fontSize: "10px", color: "#e7e7e7", fontWeight: "bold" }}>
        {data.label}
      </span>
      <span
        style={{
          fontSize: "10px",
          color: "#e7e7e7",
          fontWeight: "bold",
          backgroundColor: "#2e2e2e",
          padding: "3px 5px",
          borderRadius: "2px",
        }}
      >
        {data.options.length}
      </span>
    </div>
  );

  const alignments = [
    {
      value: "left",
      label: <FormatAlignLeftIcon style={{ fontSize: "16px" }} />,
    },
    {
      value: "center",
      label: <FormatAlignCenterIcon style={{ fontSize: "16px" }} />,
    },
    {
      value: "right",
      label: <FormatAlignRightIcon style={{ fontSize: "16px" }} />,
    },
    {
      value: "justify",
      label: <FormatAlignJustifyIcon style={{ fontSize: "16px" }} />,
    },
  ];

  const setLink = useCallback(
    (url, type, targetBlank) => {
      // cancelled
      if (url === null) {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      // empty
      if (url === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({
          href: url,
          target:
            type === "Web" || type === "Web_out"
              ? targetBlank === true
                ? "_blank"
                : "_self"
              : "_self",
        })
        .run();

      // Set italic and underline

      editor.chain().focus().setItalic().run();
      editor.chain().focus().setUnderline().run();

      setLinkMenuOpen(false);
      setType("");

      // cancelled
    },
    [editor]
  );

  const handleFontSizeChange = useCallback(
    (newFontSize, newFontSizeUnit) => {
      // editor!.commands.setFontSize(`${newFontSize}px`);
      // setFontSize(newFontSize);

      // // const newFontSizeValue = `${newFontSize}px`;

      editor!
        .chain()
        .focus()
        .setFontSize(`${newFontSize}${newFontSizeUnit}`)
        .run();
    },
    [editor]
  );

  const tipTapDidMount = async () => {
    const element = (await document.querySelector(
      ".tippy-box"
    )) as HTMLDivElement;

    if (element) {
      // Set maxWidth for element

      if (isExpanded === false) {
        element.style.width = "max-content";
      } else {
        element.style.width = "100%";
      }
    }
  };

  useEffect(() => {
    tipTapDidMount();
  }, []);

  useEffect(() => {
    tipTapDidMount();
  }, [isExpanded]);

  if (component.type === "image") {
    return <></>;
  }

  return (
    <div
      style={{
        fontSize: "14px",
        zIndex: 99999,
      }}
    >
      <BubbleMenu
        editor={editor}
        className="bubble-menu"
        tippyOptions={{
          duration: 0,
          placement: "top",
          maxWidth: isExpanded === false ? "460px" : "405px",
        }}
      >
        <div
          style={{
            display: "flex",

            flexWrap: "wrap",
            gap: "3px",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "3px",
            }}
          >
            {component.type !== "nav-item" && component.type !== "Button" && (
              <div
                style={{ display: "flex", gap: "0px", alignItems: "center" }}
              >
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "20px",
                  }}
                  onClick={() => editor.chain().focus().undo().run()}
                  className={"bubble-menu__item"}
                  disabled={!editor.can().chain().focus().undo().run()}
                >
                  <Undo style={{ fontSize: "16px" }} />
                </button>

                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "20px",
                  }}
                  onClick={() => editor.chain().focus().redo().run()}
                  className={"bubble-menu__item"}
                  disabled={!editor.can().chain().focus().redo().run()}
                >
                  <Redo style={{ fontSize: "16px" }} />
                </button>
              </div>
            )}

            <Select
              menuPortalTarget={document.getElementById(
                "react-select-container"
              )}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              onChange={(val) => {
                updateStructureComponent(
                  id,
                  "attributes.typography_id",
                  val.value
                );
              }}
              className="m-0 font-menu_input"
              styles={{
                ...DefaultReactSelectStyle,
                menuList: (styles, data) => {
                  return {
                    ...styles,
                    overflowX: "hidden",
                  };
                },
                container: (styles, data) => {
                  return {
                    ...styles,
                  };
                },
                singleValue: (styles, data) => {
                  return {
                    ...DefaultReactSelectStyle.singleValue!(styles, data),
                    overflow: "visible",
                    maxWidth: "60px",
                  };
                },
                option: (styles, data) => {
                  return {
                    ...DefaultReactSelectStyle.option!(styles, data),
                    padding: "1px 2px",
                    textAlign: "center",
                  };
                },
                control: (styles, data) => {
                  return {
                    ...DefaultReactSelectStyle.control!(styles, data),
                    minHeight: "inherit",
                    flexWrap: "nowrap",
                  };
                },
                valueContainer: (styles, data) => {
                  return {
                    ...styles,
                    padding: "0px",
                    fontSize: "12px",
                    minWidth: "inherit",
                    alignSelf: "flex-start",
                    overflow: "hidden",
                  };
                },
                menu: (styles, data) => {
                  return {
                    ...DefaultReactSelectStyle.menu!(styles, data),
                    marginTop: "2px",
                  };
                },
                dropdownIndicator: (styles, data) => {
                  return {
                    ...styles,
                    height: "100%",
                    padding: 0,
                    alignSelf: "flex-start",
                    alignItems: "center",
                    width: "12px",
                    color: "#fff",
                    svg: {
                      height: "12px",
                    },
                  };
                },
              }}
              noOptionsMessage={({}) => ""}
              value={typographies
                .map((x: any) => {
                  return { ...x, label: x.name, value: x.id };
                })
                .find((x) => x.id === component.attributes.typography_id)}
              options={typographies
                ?.map((x: any) => {
                  return { ...x, label: x.name, value: x.id };
                })
                .sort((a: any, b: any) => a.label.localeCompare(b.label))}
              placeholder=""
            ></Select>

            <div
              className="d-inline font-menu_input"
              style={{
                width: "60px",
                alignItems: "center",
                marginTop: "auto",
                maxHeight: "30px",
                height: "20px",
                padding: "4px 5px",
                marginBottom: "auto",
              }}
            >
              <span
                onClick={(e) => {
                  //editor!.commands.setFontSize(`${fontSize - 1}${fontSizeUnit}`);
                  handleFontSizeChange(fontSize - 1, fontSizeUnit);
                }}
                style={{
                  display: "flex",
                  fontSize: "15px",
                  color: "white",
                  fontWeight: "normal",
                  alignItems: "center",
                }}
              >
                <Remove style={{ fontSize: "13px" }} />
              </span>
              <input
                type="number"
                className="global-editor__input builder-input builder-input-bubble-menu"
                size={1}
                style={{
                  fontWeight: 400,
                  fontSize: "12px!important",
                }}
                value={fontSize}
                onChange={(e) => {
                  editor!.commands.setFontSize(e.target.value + fontSizeUnit);
                }}
              />

              <span
                onClick={(e) => {
                  // editor!.commands.setFontSize(`${fontSize + 1}${fontSizeUnit}`);

                  handleFontSizeChange(fontSize + 1, fontSizeUnit);
                }}
                style={{
                  display: "flex",
                  fontSize: "15px",
                  color: "white",
                  fontWeight: "normal",
                  alignItems: "center",
                }}
              >
                <Add style={{ fontSize: "13px" }} />
              </span>
            </div>

            <Select
              menuPortalTarget={document.getElementById(
                "react-select-container"
              )}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => {
                editor!.commands.setFontSize(fontSize + e!.value);
              }}
              className="m-0 font-menu_input"
              styles={{
                ...DefaultReactSelectStyle,
                menuList: (styles, data) => {
                  return {
                    ...styles,
                    overflowX: "hidden",
                  };
                },
                container: (styles, data) => {
                  return {
                    ...styles,
                  };
                },
                singleValue: (styles, data) => {
                  return {
                    ...DefaultReactSelectStyle.singleValue!(styles, data),
                    overflow: "visible",
                  };
                },
                option: (styles, data) => {
                  return {
                    ...DefaultReactSelectStyle.option!(styles, data),
                    padding: "1px 2px",
                    textAlign: "center",
                  };
                },
                control: (styles, data) => {
                  return {
                    ...DefaultReactSelectStyle.control!(styles, data),
                    minHeight: "inherit",
                    flexWrap: "nowrap",
                  };
                },
                valueContainer: (styles, data) => {
                  return {
                    ...styles,
                    padding: "0px",
                    fontSize: "12px",
                    minWidth: "inherit",
                    alignSelf: "flex-start",
                    overflow: "hidden",
                  };
                },
                menu: (styles, data) => {
                  return {
                    ...DefaultReactSelectStyle.menu!(styles, data),
                    marginTop: "2px",
                  };
                },
                dropdownIndicator: (styles, data) => {
                  return {
                    ...styles,
                    height: "100%",
                    padding: 0,
                    alignSelf: "flex-start",
                    alignItems: "center",
                    width: "12px",
                    color: "#fff",
                    svg: {
                      height: "12px",
                    },
                  };
                },
              }}
              noOptionsMessage={({}) => ""}
              value={fontSizeUnits.find((x) => x.value === fontSizeUnit)}
              options={fontSizeUnits}
              placeholder=""
            ></Select>

            <button
              onClick={() => editor!.chain().focus().toggleBold().run()}
              className={
                editor.isActive("bold")
                  ? "bubble-menu__item--active"
                  : "bubble-menu__item"
              }
            >
              <FormatBold style={{ fontSize: "17px" }} />
            </button>

            <button
              onClick={() => editor!.chain().focus().toggleItalic().run()}
              className={
                editor.isActive("italic")
                  ? "bubble-menu__item--active"
                  : "bubble-menu__item"
              }
            >
              <FormatItalic style={{ fontSize: "17px" }} />
            </button>

            <button
              onClick={() => editor!.chain().focus().toggleUnderline().run()}
              className={
                editor.isActive("underline")
                  ? "bubble-menu__item--active"
                  : "bubble-menu__item"
              }
            >
              <FormatUnderlined style={{ fontSize: "17px" }} />
            </button>

            <div style={{ position: "relative" }}>
              <button
                className="bubble-menu__item"
                onClick={() => setColorPickerOpen(!colorPickerOpen)}
                style={{}}
              >
                <FormatColorText
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                  }}
                />
              </button>

              {colorPickerOpen && (
                <>
                  <ColorPicker
                    value={
                      editor.getAttributes("textStyle").color
                        ? editor.getAttributes("textStyle").color
                        : "#000"
                    }
                    title="Text color"
                    onChange={(e: any) => {
                      editor.chain().focus().setColor(e).run();
                    }}
                    setColorPickerOpen={setColorPickerOpen}
                    returnType="hex"
                  />
                </>
              )}
            </div>

            {component.type === "nav-item" || component.type === "Button" ? (
              <>
                <button
                  onClick={() => editor!.chain().focus().unsetAllMarks().run()}
                  className="bubble-menu__item"
                >
                  <FormatClear style={{ fontSize: "17px" }} />
                </button>
              </>
            ) : (
              <></>
            )}

            {component.type !== "nav-item" && (
              <>
                <Select
                  isSearchable={false}
                  className="m-0"
                  styles={{
                    ...DefaultReactSelectStyle,
                    menuList: (styles, data) => {
                      return {
                        ...styles,
                        overflowX: "hidden",
                      };
                    },
                    container: (styles, data) => {
                      return {
                        ...styles,
                      };
                    },
                    singleValue: (styles, data) => {
                      return {
                        ...DefaultReactSelectStyle.singleValue!(styles, data),
                        overflow: "visible",
                        display: "flex",
                        alignItems: "center",
                      };
                    },
                    option: (styles, data) => {
                      return {
                        ...DefaultReactSelectStyle.option!(styles, data),
                        padding: "1px 2px",
                        textAlign: "center",
                      };
                    },
                    control: (styles, data) => {
                      return {
                        ...DefaultReactSelectStyle.control!(styles, data),
                        minHeight: "inherit",
                        flexWrap: "nowrap",
                      };
                    },
                    valueContainer: (styles, data) => {
                      return {
                        ...styles,
                        padding: "0px",
                        fontSize: "12px",
                        minWidth: "inherit",
                        alignSelf: "flex-start",
                        overflow: "hidden",
                      };
                    },
                    menu: (styles, data) => {
                      return {
                        ...DefaultReactSelectStyle.menu!(styles, data),
                        marginTop: "2px",
                      };
                    },
                    dropdownIndicator: (styles, data) => {
                      return {
                        ...styles,
                        height: "100%",
                        padding: 0,
                        alignSelf: "flex-start",
                        alignItems: "center",
                        width: "12px",
                        color: "#fff",
                        svg: {
                          height: "12px",
                        },
                      };
                    },
                  }}
                  noOptionsMessage={({}) => ""}
                  value={alignments.find((x) => {
                    return (
                      x.value ===
                      ["left", "center", "right", "justify"].find(
                        (alignment) => {
                          return editor.isActive({ textAlign: alignment });
                        }
                      )
                    );
                  })}
                  options={alignments}
                  onChange={(val) => {
                    if (component.type === "Button" && val?.value === "left") {
                      updateStructureComponent(
                        id,
                        "attributes.styles.textAlign",
                        "left"
                      );
                    }

                    if (val) {
                      editor.chain().focus().setTextAlign(val.value).run();
                    }
                  }}
                  placeholder=""
                ></Select>
              </>
            )}

            {component.type !== "nav-item" && component.type !== "Button" ? (
              <>
                <div>
                  <button
                    onClick={() => {
                      setLinkMenuOpen(!linkMenuOpen);
                    }}
                    className={
                      editor.isActive("link")
                        ? "bubble-menu__item--active"
                        : "bubble-menu__item"
                    }
                  >
                    <LinkIcon style={{ fontSize: "17px" }} />
                  </button>

                  {linkMenuOpen && (
                    <div className="bubble-menu__link">
                      <div style={{ display: "flex", gap: "5px" }}>
                        <Select
                          onChange={(e) => {
                            if (e!.value == "None") {
                              setUrl(null);
                            }

                            // if (e!.value === "Page") {
                            //   setUrl(groupedOptions[0].url);
                            // }
                            if (e!.value === "Web") {
                              setUrl("/");
                            }

                            if (e!.value === "Web_out") {
                              setUrl("https://");
                            }

                            if (e!.value === "Anchor") {
                              setUrl("#");
                            }

                            if (e!.value === "Phone") {
                              setUrl("tel:");
                            }

                            if (e!.value === "Email") {
                              setUrl("mailto:");
                            }

                            setType(e!.value);

                            // setType(e!.value);
                            // if (e!.value === "Page") {
                            //   setUrl(webpagesList[0].value);
                            // } else if (type === "Page") {
                            //   setUrl("");
                            // } else {
                            //   setUrl(null);
                            // }
                          }}
                          isSearchable={false}
                          className="m-0 link-menu_input"
                          styles={{
                            ...TextEditorSelectStyle,
                            container: (base) => ({
                              ...base,

                              maxWidth: "100%",
                              width: "fit-content",
                            }),
                          }}
                          noOptionsMessage={({}) => ""}
                          options={types}
                          value={types.find((x) => x.value === type)}
                          placeholder="Link type:"
                        ></Select>

                        {(type === "Web_out" || type === "Web") && (
                          <>
                            <Select
                              onChange={(e) => {
                                if (e!.value === "_blank") {
                                  setTargetBlank(true);
                                } else if (e!.value === "_self") {
                                  setTargetBlank(false);
                                }
                              }}
                              isSearchable={false}
                              className="m-0 link-menu_input"
                              styles={{
                                ...TextEditorSelectStyle,
                                container: (base) => ({
                                  ...base,

                                  maxWidth: "100%",
                                  width: "fit-content",
                                }),
                              }}
                              noOptionsMessage={({}) => ""}
                              options={targetTypes}
                              value={targetTypes.find((x) => {
                                if (targetBlank === true) {
                                  return x.value === "_blank";
                                } else {
                                  return x.value === "_self";
                                }
                              })}
                              placeholder="Select link type:"
                            ></Select>
                          </>
                        )}
                      </div>

                      {type === "Web" && (
                        <Select
                          onChange={(e) => {
                            setUrl(`${e!.value}`);
                          }}
                          isSearchable={false}
                          className="m-0 link-menu_input"
                          styles={{
                            ...TextEditorSelectStyle,

                            container: (base) => ({
                              ...base,
                              width: "100%",
                              maxWidth: "100%",
                            }),

                            control: (styles, data) => ({
                              ...DefaultReactSelectStyle.control!(styles, data),
                              width: "100%",
                            }),

                            option: (styles, data) => ({
                              ...DefaultReactSelectStyle.option!(styles, data),
                              textAlign: "left",

                              padding: "2px 12px",
                            }),
                          }}
                          noOptionsMessage={({}) => "aefaefaefe"}
                          value={groupedOptions.map((x) => {
                            if (url === "/") {
                              return x.options.find((y) => y.value === "");
                            } else if (url) {
                              return x.options.find((y) => y.value === url);
                            } else {
                              return;
                            }
                          })}
                          options={groupedOptions}
                          placeholder="Select a page"
                          formatGroupLabel={formatGroupLabel}
                        ></Select>
                      )}

                      {type !== "None" && (
                        <div>
                          <input
                            style={{
                              fontSize: "11px",
                              width: "100%",
                              fontFamily: "sans-serif",
                            }}
                            className="link-menu_input"
                            value={url}
                            onChange={(e) => {
                              setUrl(e.target.value);
                            }}
                          />
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          maxHeight: "25px",
                        }}
                      >
                        <div
                          onClick={() => setLink(url, type, targetBlank)}
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            padding: "3px 6px",
                            borderRadius: "3px",
                            cursor: "pointer",
                            fontWeight: "normal",
                            fontSize: "11px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "sans-serif",
                          }}
                        >
                          Save
                        </div>
                        <div
                          onClick={() => setLinkMenuOpen(false)}
                          style={{
                            cursor: "pointer",
                            color: "white",
                            fontWeight: "normal",
                            fontSize: "11px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "sans-serif",
                          }}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            {isExpanded && (
              <>
                <div style={{ position: "relative" }}>
                  <button
                    className="bubble-menu__item"
                    onClick={() =>
                      setHighlightColorPicker(!highlightColorPicker)
                    }
                    style={{}}
                  >
                    <FormatPaint
                      style={{
                        fontSize: "17px",
                        color: editor.getAttributes("textStyle").highlight
                          ? editor.getAttributes("textStyle").highlight
                          : "#fff",
                      }}
                    />

                    {/* <FormatColorText
                    style={{
                      fontSize: "17px",
                      color: editor.getAttributes("textStyle").color
                        ? editor.getAttributes("textStyle").color
                        : "#fff",
                    }}
                  /> */}
                  </button>

                  {highlightColorPicker && (
                    <>
                      <ColorPicker
                        value={
                          editor.getAttributes("textStyle").highlight
                            ? editor.getAttributes("textStyle").highlight
                            : "#000"
                        }
                        title="Highlight color"
                        onChange={(e: any) => {
                          editor
                            .chain()
                            .focus()
                            .toggleHighlight({ color: e })
                            .run();
                        }}
                        setColorPickerOpen={setHighlightColorPicker}
                        returnType="hex"
                      />
                    </>
                  )}
                </div>

                <button
                  onClick={() => editor!.chain().focus().toggleStrike().run()}
                  className={
                    editor.isActive("strike")
                      ? "bubble-menu__item--active"
                      : "bubble-menu__item"
                  }
                >
                  <StrikethroughS style={{ fontSize: "17px" }} />
                </button>

                <button
                  onClick={() =>
                    editor!.chain().focus().toggleSubscript().run()
                  }
                  className={
                    editor.isActive("subscript")
                      ? "bubble-menu__item--active"
                      : "bubble-menu__item"
                  }
                >
                  <Subscript style={{ fontSize: "17px" }} />
                </button>

                <button
                  onClick={() =>
                    editor!.chain().focus().toggleSuperscript().run()
                  }
                  className={
                    editor.isActive("superscript")
                      ? "bubble-menu__item--active"
                      : "bubble-menu__item"
                  }
                >
                  <Superscript style={{ fontSize: "17px" }} />
                </button>

                <button
                  onClick={() =>
                    editor!.chain().focus().toggleBulletList().run()
                  }
                  className={
                    editor.isActive("bulletList")
                      ? "bubble-menu__item--active"
                      : "bubble-menu__item"
                  }
                >
                  <FormatListBulleted style={{ fontSize: "17px" }} />
                </button>

                <button
                  onClick={() =>
                    editor!.chain().focus().toggleOrderedList().run()
                  }
                  className={
                    editor.isActive("orderedList")
                      ? "bubble-menu__item--active"
                      : "bubble-menu__item"
                  }
                >
                  <FormatListNumbered style={{ fontSize: "17px" }} />
                </button>

                <button
                  onClick={() => editor!.chain().focus().unsetAllMarks().run()}
                  className="bubble-menu__item"
                >
                  <FormatClear style={{ fontSize: "17px" }} />
                </button>
              </>
            )}

            {!isExpanded &&
              component.type !== "nav-item" &&
              component.type !== "Button" && (
                <>
                  <div
                    onClick={() => setIsExpanded(!isExpanded)}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MoreHoriz style={{ fontSize: "17px", color: "white" }} />
                  </div>
                </>
              )}
          </div>
        </div>
      </BubbleMenu>
    </div>
  );
}
