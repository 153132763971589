import API from "../api/api";
import StructureAPI, {
  loadWebpageComponents,
} from "../store/reducers/builderReducers/structure/structureApi";
import { useSelector } from "react-redux";
import { Typography } from "../store/reducers/builderReducers/typographies/typographiesApi";
import {
  FooterComponent,
  HeaderComponent,
} from "../components/_default/interfaces/base";
import { AllComponents } from "../components/_default/interfaces/base";
import store from "../store/store";
import { EditAttributes } from "@mui/icons-material";
import TemplateAPI from "../store/reducers/builderReducers/templates/templateApi";
import { useState } from "react";
import usePageLanguage from "../components/_default/hooks/usePageLanguage";

interface ReusableComponents {
  footers: FooterComponent[];
  headers: HeaderComponent[];
}

interface addHeaderProps {
  headerId?: number | null;
}

export function addHeader(
  headerId?: number | null,
  headerName: string = "",
  locale?: string,
  copy?: boolean
) {
  const state = store.getState();
  const typographies = state.builder.typographies;
  const reusableComponents = state.builder.reusableComponents;
  const pages = state.builder.pages;
  const languages = state.builder.languageSettings;

  console.log(languages, "THESE ARE THE LANGUAGES");

  const currentPageId = state.builder.pages.active;
  const currentPage = state.builder.pages.list.filter(
    (x) => x.id === currentPageId
  )[0];

  const currentPageLanguage = languages.activeLanguages.find(
    (x) => x.id === currentPage.website_language_id
  );

  if (!pages.active) {
    return false;
  }

  const templateLanguageOptions = languages.activeLanguages.map((x) => {
    return {
      label: x.language.name,
      value: x.id,
      locale: x.language.shortname,
    };
  });

  const cachedTemplateInfo = {
    name: headerName ? headerName : "New Header",
    language_id: templateLanguageOptions.find((x: any) => x.locale === locale)
      ?.value,
    use: "default",
  };

  if (headerId && !headerName) {
    StructureAPI.addExistingComponent(pages.active, {
      id: headerId,
      parent_id: null,
      sort_order: -1,
    }).then(() => {
      loadWebpageComponents(pages.active!);
    });

    return true;
  }

  if (
    true &&
    headerName &&
    headerName !== "" &&
    locale &&
    locale !== "" &&
    !copy
  ) {
    StructureAPI.addComponent(pages.active, {
      type: "header",
      language_id: currentPageLanguage?.id,
      sort_order: -1,
      attributes: [
        {
          type: "attributes",
          name: "components",
          value: {
            logo: { alignment: "left", type: "logo" },
            nav: { alignment: "center", display: "block" },
            cart: { alignment: "right", display: "block" },
            language: {
              display: "block",
              type: "image",
              color: "#fff",
              size: "small",
              dropdown: "none",
            },
          },
        },
        { name: "paddingTop", value: "10px", type: "styles" },
        { name: "paddingLeft", value: "20px", type: "styles" },
        { name: "paddingBottom", value: "10px", type: "styles" },
        { name: "paddingRight", value: "20px", type: "styles" },
      ],
      childElements: [
        {
          type: "section",
          sort_order: 1,
          attributes: [
            { name: "classes", value: "col col-3", type: "attributes" },
            { name: "paddingLeft", value: "0px", type: "styles" },
            { name: "paddingRight", value: "0px", type: "styles" },
            { name: "paddingTop", value: "10px", type: "styles" },
            { name: "paddingBottom", value: "10px", type: "styles" },
            { name: "marginTop", value: "0px", type: "styles" },
            { name: "marginBottom", value: "0px", type: "styles" },
            { name: "marginRight", value: "0px", type: "styles" },
            { name: "marginLeft", value: "0px", type: "styles" },
          ],
          childElements: [
            {
              type: "image",
              sort_order: 1,
              attributes: [
                {
                  name: "maintainAspectRatio",
                  value: true,
                  type: "attributes",
                },
                { name: "width", value: "100%", type: "styles" },
                { name: "paddingLeft", value: "0px", type: "styles" },
                { name: "paddingRight", value: "0px", type: "styles" },
                { name: "paddingTop", value: "0px", type: "styles" },
                { name: "paddingBottom", value: "0px", type: "styles" },
                { name: "maxWidth", value: "122px", type: "styles" },
                { name: "minWidth", value: "100px", type: "styles" },
                { name: "minHeight", value: "40px", type: "styles" },
              ],
            },
          ],
        },
        {
          type: "section",
          sort_order: 1,
          attributes: [
            { name: "classes", value: "col col-6", type: "attributes" },
            { name: "paddingLeft", value: "0px", type: "styles" },
            { name: "paddingRight", value: "0px", type: "styles" },
            { name: "paddingTop", value: "10px", type: "styles" },
            { name: "paddingBottom", value: "10px", type: "styles" },
            { name: "marginTop", value: "0px", type: "styles" },
            { name: "marginBottom", value: "0px", type: "styles" },
            { name: "marginRight", value: "0px", type: "styles" },
            { name: "marginLeft", value: "0px", type: "styles" },
          ],
          childElements: [
            {
              type: "navigation-container",
              sort_order: 1,
              attributes: [
                { name: "paddingLeft", value: "10px", type: "styles" },
                { name: "paddingRight", value: "10px", type: "styles" },
                { name: "paddingTop", value: "10px", type: "styles" },
                { name: "paddingBottom", value: "10px", type: "styles" },
              ],

              childElements: [
                {
                  type: "nav-item",
                  sort_order: 1,
                  attributes: [
                    {
                      type: "attributes",
                      name: "text",
                      value: "Avaleht",
                    },
                    {
                      type: "attributes",
                      name: "link",
                      value: { url: "", type: "None", target: "" },
                    },
                    {
                      type: "attributes",
                      name: "typography_id",
                      value: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "section",
          sort_order: 1,
          attributes: [
            {
              name: "classes",
              value: "col col-3 text-end text-xs-end text-sm-end",
              type: "attributes",
            },
            { name: "paddingLeft", value: "0px", type: "styles" },
            { name: "paddingRight", value: "0px", type: "styles" },
            { name: "paddingTop", value: "10px", type: "styles" },
            { name: "paddingBottom", value: "10px", type: "styles" },
            { name: "marginTop", value: "0px", type: "styles" },
            { name: "marginBottom", value: "0px", type: "styles" },
            { name: "marginRight", value: "0px", type: "styles" },
            { name: "marginLeft", value: "0px", type: "styles" },
          ],
          childElements: [
            {
              type: "cart",
              sort_order: 1,
              attributes: [
                { name: "marginTop", value: "10px", type: "styles" },
              ],
            },
          ],
        },
      ],
    }).then((x) => {
      // Save component as template

      const newTemplate = {
        id: 0,
        website_id: 0,
        name: headerName ? headerName : "",
        use: "",
        language_id: null,
        webpage_component_id: x.payload[Object.keys(x.payload)[0]].id,
        type: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        component: null,
        webpages: [],
      };

      TemplateAPI.saveTemplateById(newTemplate.id, {
        ...newTemplate,
        ...cachedTemplateInfo,
        visible: true,
      }).then((res) => {
        loadWebpageComponents(pages.active!);
      });
    });

    return true;
  }

  if (copy && locale && headerName && headerId) {
    StructureAPI.addExistingComponent(pages.active, {
      id: headerId,
      parent_id: null,
      sort_order: -1,
    }).then(() => {
      loadWebpageComponents(pages.active!).then((x) => {
        const newTemplate = {
          id: 0,
          website_id: 0,
          name: headerName ? headerName : "",
          use: "",
          language_id: null,
          webpage_component_id: x.components[headerId].id,
          type: "",
          created_at: "",
          updated_at: "",
          deleted_at: "",
          component: null,
          webpages: [],
        };

        TemplateAPI.saveTemplateById(newTemplate.id, {
          ...newTemplate,
          ...cachedTemplateInfo,
          visible: true,
        }).then((res) => {
          loadWebpageComponents(pages.active!);
        });
      });
    });
  }

  // if (currentPage) {
  //   const currentLangHeader = reusableComponents.headers.find(
  //     (x) => x.language_id === currentPage.website_language_id
  //   );

  //   if (
  //     currentLangHeader &&
  //     currentLangHeader.childElements.find((x) => x.type === "language-select")
  //   ) {
  //     StructureAPI.addExistingComponent(pages.active, {
  //       id: currentLangHeader.id,
  //       parent_id: null,
  //       sort_order: -1,
  //     }).then(() => {
  //       loadWebpageComponents(pages.active!);
  //     });
  //     return true;
  //   } else if (
  //     currentLangHeader &&
  //     !currentLangHeader.childElements.find((x) => x.type === "language-select")
  //   ) {
  //     // StructureAPI.addExistingComponent(pages.active, {
  //     //   id: currentLangHeader.id,
  //     //   parent_id: null,
  //     //   sort_order: -1,
  //     //   childElements: [
  //     //     ...currentLangHeader.childElements,

  //     //     {
  //     //       type: "language-select", // language-select
  //     //       sort_order: 4,
  //     //       attributes: [
  //     //         {
  //     //           type: "attributes",
  //     //           name: "typography_id",
  //     //           value: typographies.find((x) => x.tag == "p")?.id ?? null,
  //     //         },
  //     //       ],
  //     //     },
  //     //   ],
  //     // }).then(() => {
  //     //   loadWebpageComponents(pages.active!);
  //     // });

  //     StructureAPI.addComponent(pages.active, {
  //       type: "header",
  //       language_id: currentPageLanguage?.id,
  //       sort_order: -1,
  //       attributes: [
  //         {
  //           type: "attributes",
  //           name: "components",
  //           value: {
  //             logo: { alignment: "left", type: "logo" },
  //             nav: { alignment: "center", display: "block" },
  //             cart: { alignment: "right", display: "block" },
  //             language: {
  //               display: "block",
  //               type: "image",
  //               color: "#fff",
  //               size: "small",
  //               dropdown: "none",
  //             },
  //           },
  //         },
  //       ],
  //       childElements: [
  //         {
  //           type: "logo",
  //           sort_order: 1,
  //           attributes: [
  //             {
  //               type: "attributes",
  //               name: "logo",
  //               value: {
  //                 Desktop: {
  //                   active: true,
  //                   styles: {
  //                     backgroundImage:
  //                       "url(/assets/images/default-img-50x50.webp)",
  //                     backgroundPosition: "center top",
  //                     backgroundSize: "50px 50px",
  //                     backgroundRepeat: "repeat",
  //                     backgroundOrigin: "padding-box",
  //                     backgroundAttachment: "scroll",
  //                   },
  //                 },
  //                 Mobile: {
  //                   active: true,
  //                   styles: {
  //                     backgroundImage:
  //                       "url(/assets/images/default-img-50x50.webp)",
  //                     backgroundPosition: "center top",
  //                     backgroundSize: "50px 50px",
  //                     backgroundRepeat: "repeat",
  //                     backgroundOrigin: "padding-box",
  //                     backgroundAttachment: "scroll",
  //                   },
  //                 },
  //               },
  //             },
  //           ],
  //         },
  //         {
  //           type: "nav",
  //           sort_order: 2,
  //           attributes: [
  //             { name: "paddingTop", value: "20px", type: "styles" },
  //             { name: "paddingLeft", value: "20px", type: "styles" },
  //             { name: "paddingBottom", value: "20px", type: "styles" },
  //             { name: "paddingRight", value: "20px", type: "styles" },
  //           ],
  //           childElements: [
  //             {
  //               type: "nav-item",
  //               sort_order: 1,
  //               attributes: [
  //                 {
  //                   type: "attributes",
  //                   name: "text",
  //                   value: "Avaleht",
  //                 },
  //                 {
  //                   type: "attributes",
  //                   name: "link",
  //                   value: { url: "/", type: "Web", target: "" },
  //                 },
  //               ],
  //             },
  //             {
  //               type: "nav-item",
  //               sort_order: 2,
  //               attributes: [
  //                 {
  //                   type: "attributes",
  //                   name: "text",
  //                   value: "Products",
  //                 },
  //                 {
  //                   type: "attributes",
  //                   name: "link",
  //                   value: {
  //                     url: "https://google.ee",
  //                     type: "Web",
  //                     target: "_blank",
  //                   },
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           type: "cart",
  //           sort_order: 3,
  //           attributes: [
  //             { type: "styles", name: "display", value: "flex" },
  //             { type: "styles", name: "alignItems", value: "center" },
  //             { type: "styles", name: "borderLeftWidth", value: "1px" },
  //             { type: "styles", name: "borderRightWidth", value: "1px" },
  //             { type: "styles", name: "borderBottomWidth", value: "1px" },
  //             { type: "styles", name: "borderTopWidth", value: "1px" },
  //             { type: "styles", name: "borderLeftColor", value: "#048d04" },
  //             { type: "styles", name: "borderRightColor", value: "#048d04" },
  //             { type: "styles", name: "borderBottomColor", value: "#048d04" },
  //             { type: "styles", name: "borderTopColor", value: "#048d04" },
  //             { type: "styles", name: "borderTopLeftRadius", value: "20px" },
  //             { type: "styles", name: "borderTopRightRadius", value: "20px" },
  //             { type: "styles", name: "borderBottomLeftRadius", value: "20px" },
  //             {
  //               type: "styles",
  //               name: "borderBottomRightRadius",
  //               value: "20px",
  //             },
  //             { type: "styles", name: "borderLeftStyle", value: "solid" },
  //             { type: "styles", name: "borderRightStyle", value: "solid" },
  //             { type: "styles", name: "borderBottomStyle", value: "solid" },
  //             { type: "styles", name: "borderTopStyle", value: "solid" },
  //             { type: "styles", name: "paddingLeft", value: "13px" },
  //             { type: "styles", name: "paddingRight", value: "13px" },
  //             { type: "styles", name: "paddingBottom", value: "7px" },
  //             { type: "styles", name: "paddingTop", value: "7px" },
  //             { type: "styles", name: "color", value: "#ffffff!important" },

  //             {
  //               type: "attributes",
  //               name: "typography_id",
  //               value: typographies.find((x) => x.tag == "p")?.id ?? null,
  //             },
  //           ],
  //         },

  //         {
  //           type: "language-select", // language-select
  //           sort_order: 4,
  //           attributes: [
  //             {
  //               type: "attributes",
  //               name: "typography_id",
  //               value: typographies.find((x) => x.tag == "p")?.id ?? null,
  //             },
  //           ],
  //         },
  //       ],
  //     }).then(() => {
  //       loadWebpageComponents(pages.active!);
  //     });
  //   } else {
  //     StructureAPI.addComponent(pages.active, {
  //       type: "header",
  //       language_id: currentPageLanguage?.id,
  //       sort_order: -1,
  //       attributes: [
  //         {
  //           type: "attributes",
  //           name: "components",
  //           value: {
  //             logo: { alignment: "left", type: "logo" },
  //             nav: { alignment: "center", display: "block" },
  //             cart: { alignment: "right", display: "block" },
  //             language: {
  //               display: "block",
  //               type: "image",
  //               color: "#fff",
  //               size: "small",
  //               dropdown: "none",
  //             },
  //           },
  //         },
  //       ],
  //       childElements: [
  //         {
  //           type: "logo",
  //           sort_order: 1,
  //           attributes: [
  //             {
  //               type: "attributes",
  //               name: "logo",
  //               value: {
  //                 Desktop: {
  //                   active: true,
  //                   styles: {
  //                     backgroundImage:
  //                       "url(/assets/images/default-img-50x50.webp)",
  //                     backgroundPosition: "center top",
  //                     backgroundSize: "50px 50px",
  //                     backgroundRepeat: "repeat",
  //                     backgroundOrigin: "padding-box",
  //                     backgroundAttachment: "scroll",
  //                   },
  //                 },
  //                 Mobile: {
  //                   active: true,
  //                   styles: {
  //                     backgroundImage:
  //                       "url(/assets/images/default-img-50x50.webp)",
  //                     backgroundPosition: "center top",
  //                     backgroundSize: "50px 50px",
  //                     backgroundRepeat: "repeat",
  //                     backgroundOrigin: "padding-box",
  //                     backgroundAttachment: "scroll",
  //                   },
  //                 },
  //               },
  //             },
  //           ],
  //         },
  //         {
  //           type: "nav",
  //           sort_order: 2,
  //           attributes: [
  //             { name: "paddingTop", value: "20px", type: "styles" },
  //             { name: "paddingLeft", value: "20px", type: "styles" },
  //             { name: "paddingBottom", value: "20px", type: "styles" },
  //             { name: "paddingRight", value: "20px", type: "styles" },
  //           ],
  //           childElements: [
  //             {
  //               type: "nav-item",
  //               sort_order: 1,
  //               attributes: [
  //                 {
  //                   type: "attributes",
  //                   name: "text",
  //                   value: "Avaleht",
  //                 },
  //                 {
  //                   type: "attributes",
  //                   name: "link",
  //                   value: { url: "/", type: "Web", target: "" },
  //                 },
  //               ],
  //             },
  //             {
  //               type: "nav-item",
  //               sort_order: 2,
  //               attributes: [
  //                 {
  //                   type: "attributes",
  //                   name: "text",
  //                   value: "Tooted",
  //                 },
  //                 {
  //                   type: "attributes",
  //                   name: "link",
  //                   value: { url: "/", type: "Web", target: "" },
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           type: "cart",
  //           sort_order: 3,
  //           attributes: [
  //             { type: "styles", name: "display", value: "flex" },
  //             { type: "styles", name: "alignItems", value: "center" },
  //             { type: "styles", name: "borderLeftWidth", value: "1px" },
  //             { type: "styles", name: "borderRightWidth", value: "1px" },
  //             { type: "styles", name: "borderBottomWidth", value: "1px" },
  //             { type: "styles", name: "borderTopWidth", value: "1px" },
  //             { type: "styles", name: "borderLeftColor", value: "#048d04" },
  //             { type: "styles", name: "borderRightColor", value: "#048d04" },
  //             { type: "styles", name: "borderBottomColor", value: "#048d04" },
  //             { type: "styles", name: "borderTopColor", value: "#048d04" },
  //             { type: "styles", name: "borderTopLeftRadius", value: "20px" },
  //             { type: "styles", name: "borderTopRightRadius", value: "20px" },
  //             { type: "styles", name: "borderBottomLeftRadius", value: "20px" },
  //             {
  //               type: "styles",
  //               name: "borderBottomRightRadius",
  //               value: "20px",
  //             },
  //             { type: "styles", name: "borderLeftStyle", value: "solid" },
  //             { type: "styles", name: "borderRightStyle", value: "solid" },
  //             { type: "styles", name: "borderBottomStyle", value: "solid" },
  //             { type: "styles", name: "borderTopStyle", value: "solid" },
  //             { type: "styles", name: "paddingLeft", value: "13px" },
  //             { type: "styles", name: "paddingRight", value: "13px" },
  //             { type: "styles", name: "paddingBottom", value: "7px" },
  //             { type: "styles", name: "paddingTop", value: "7px" },
  //             { type: "styles", name: "color", value: "#ffffff!important" },

  //             {
  //               type: "attributes",
  //               name: "typography_id",
  //               value: typographies.find((x) => x.tag == "p")?.id ?? null,
  //             },
  //           ],
  //         },

  //         {
  //           type: "language-select", // language-select
  //           sort_order: 4,
  //           attributes: [
  //             {
  //               type: "attributes",
  //               name: "typography_id",
  //               value: typographies.find((x) => x.tag == "p")?.id ?? null,
  //             },
  //           ],
  //         },
  //       ],
  //     }).then(() => {
  //       loadWebpageComponents(pages.active!);
  //     });
  //   }
  // }

  return true;
}

export function addFooter() {
  const state = store.getState();
  const typographies = state.builder.typographies;
  const reusableComponents = state.builder.reusableComponents;
  const pages = state.builder.pages;
  const languages = state.builder.languageSettings;
  const currentPageId = state.builder.pages.active;
  const currentPage = state.builder.pages.list.filter(
    (x) => x.id === currentPageId
  )[0];

  const currentPageLanguage = languages.activeLanguages.find(
    (x) => x.id === currentPage.website_language_id
  );

  if (!pages.active) {
    return false;
  }
  if (currentPage) {
    const currentLangFooter = reusableComponents?.footers.find(
      (x) => x.language_id === currentPage.website_language_id
    );

    if (currentLangFooter) {
      StructureAPI.addExistingComponent(pages.active, {
        id: currentLangFooter.id,
        parent_id: null,
        sort_order: 999,
      }).then(() => {
        loadWebpageComponents(pages.active!);
      });
      return true;
    } else {
      StructureAPI.addComponent(pages.active, {
        type: "footer",
        sort_order: 99999,
        language_id: currentPageLanguage?.id,
        attributes: [
          {
            type: "attributes",
            name: "classes",
            value: "builder-footer",
          },
        ],
        childElements: [
          {
            type: "section",
            sort_order: 1,
            attributes: [
              {
                type: "attributes",
                name: "classes",
                value: "container-fluid",
              },
              { name: "paddingTop", value: "10px", type: "styles" },
              { name: "paddingLeft", value: "10px", type: "styles" },
              { name: "paddingBottom", value: "10px", type: "styles" },
              { name: "paddingRight", value: "10px", type: "styles" },
            ],
            childElements: [
              {
                type: "section",
                sort_order: 1,
                attributes: [
                  {
                    type: "attributes",
                    name: "classes",
                    value: "row",
                  },
                  { name: "paddingTop", value: "60px", type: "styles" },
                  { name: "paddingLeft", value: "60px", type: "styles" },
                  { name: "paddingBottom", value: "60px", type: "styles" },
                  { name: "paddingRight", value: "60px", type: "styles" },
                ],
                childElements: [
                  {
                    type: "section",
                    sort_order: 1,
                    attributes: [
                      {
                        type: "attributes",
                        name: "classes",
                        value: "col",
                      },
                      { name: "paddingTop", value: "60px", type: "styles" },
                      { name: "paddingLeft", value: "60px", type: "styles" },
                      { name: "paddingBottom", value: "60px", type: "styles" },
                      { name: "paddingRight", value: "60px", type: "styles" },
                    ],
                    childElements: [
                      {
                        type: "text",
                        sort_order: 1,
                        attributes: [
                          {
                            type: "attributes",
                            name: "text",
                            value: "text",
                          },
                          {
                            type: "attributes",
                            name: "typography_id",
                            value:
                              typographies.find((x) => x.tag == "p")?.id ??
                              null,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      }).then(() => {
        loadWebpageComponents(pages.active!);
      });
    }
  }

  return true;
}

export function addCookies() {
  const state = store.getState();
  const typographies = state.builder.typographies;
  const reusableComponents = state.builder.reusableComponents;
  const pages = state.builder.pages;
  if (!pages.active) {
    return false;
  }
  // if (reusableComponents.footers.length) {
  //   StructureAPI.addExistingComponent(pages.active, {
  //     id: reusableComponents.footers[0].id,
  //     parent_id: null,
  //     sort_order: 999,
  //   }).then(() => {
  //     loadWebpageComponents(pages.active!);
  //   });
  //   return true;
  // }
  // StructureAPI.addComponent(pages.active, {
  //   type: "footer",
  //   sort_order: 999,
  //   attributes: [
  //     {
  //       type: "attributes",
  //       name: "classes",
  //       value: "builder-footer",
  //     },
  //   ],
  //   childElements: [
  //     {
  //       type: "section",
  //       sort_order: 1,
  //       attributes: [
  //         {
  //           type: "attributes",
  //           name: "classes",
  //           value: "container-fluid",
  //         },
  //         { name: "paddingTop", value: "10px", type: "styles" },
  //         { name: "paddingLeft", value: "10px", type: "styles" },
  //         { name: "paddingBottom", value: "10px", type: "styles" },
  //         { name: "paddingRight", value: "10px", type: "styles" },
  //       ],
  //       childElements: [
  //         {
  //           type: "section",
  //           sort_order: 1,
  //           attributes: [
  //             {
  //               type: "attributes",
  //               name: "classes",
  //               value: "row",
  //             },
  //             { name: "paddingTop", value: "60px", type: "styles" },
  //             { name: "paddingLeft", value: "60px", type: "styles" },
  //             { name: "paddingBottom", value: "60px", type: "styles" },
  //             { name: "paddingRight", value: "60px", type: "styles" },
  //           ],
  //           childElements: [
  //             {
  //               type: "section",
  //               sort_order: 1,
  //               attributes: [
  //                 {
  //                   type: "attributes",
  //                   name: "classes",
  //                   value: "col",
  //                 },
  //                 { name: "paddingTop", value: "60px", type: "styles" },
  //                 { name: "paddingLeft", value: "60px", type: "styles" },
  //                 { name: "paddingBottom", value: "60px", type: "styles" },
  //                 { name: "paddingRight", value: "60px", type: "styles" },
  //               ],
  //               childElements: [
  //                 {
  //                   type: "text",
  //                   sort_order: 1,
  //                   attributes: [
  //                     {
  //                       type: "attributes",
  //                       name: "text",
  //                       value: "text",
  //                     },
  //                     {
  //                       type: "attributes",
  //                       name: "typography_id",
  //                       value:
  //                         typographies.find((x) => x.tag == "p")?.id ?? null,
  //                     },
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // }).then(() => {
  //   loadWebpageComponents(pages.active!);
  // });
  return true;
}
