import React, { useState } from "react";

import "./SaveConfirmation.scss";
import { setActivePage } from "../../../../store/reducers/builderReducers/pages/pagesActions";
import StructureAPI from "../../../../store/reducers/builderReducers/structure/structureApi";
import { useAppSelector } from "../../../../store/hooks";
import { Spinner } from "react-bootstrap";
import { updateEditorState } from "../../../../store/reducers/builderReducers/editor/editorActions";
import { WarningAmber } from "@mui/icons-material";
import {
  getUpdatedComponents,
  getUpdatedWebpageComponents,
  logErrorToBackend,
} from "../../../_default/utilities/helpers";

export default function SaveConfirmation() {
  const webpage_id = useAppSelector((state) => state.builder.pages.active);
  const structure = useAppSelector((state) => state.builder.structure);
  const [saving, setSaving] = useState(0);

  return (
    <div className="editor-confirmation__container__save">
      <div className="editor-confirmation__content">
        <span className="editor-confirmation__text">
          <WarningAmber /> You have unsaved changes!
        </span>
        <div className="editor-confirmation__text_description">
          Are you sure you want to leave? Changes has been made to this page.
          Leaving the page will discard your changes. NB! This action cannot be
          undone.
        </div>

        <div className="editor-confirmation__buttons">
          <button
            disabled={saving === 1}
            style={{
              background: "transparent",
              color: "white",

              borderRadius: "3px",
              cursor: "pointer",
            }}
            onClick={() => {
              const parent = document.getElementsByClassName(
                "editor-confirmation__container__save"
              )[0];

              parent?.classList.remove("editor-confirmation__container-open");
            }}
          >
            Cancel
          </button>
          <div style={{ display: "flex", gap: "7px" }}>
            <button
              disabled={saving === 1}
              style={{
                background: "#e03a3a",
                border: "0px solid #e03a3a",
                borderRadius: "3px",
                color: "white",
              }}
              onClick={() => {
                const parentElement = document.getElementsByClassName(
                  "editor-confirmation__container__save"
                )[0];

                const pageId = parentElement?.getAttribute("page-id");
                const templateId = parentElement?.getAttribute("template-id");

                if (pageId) {
                  setActivePage(Number(pageId));
                  updateEditorState({
                    hasEdited: false,
                  });
                }

                if (templateId) {
                  updateEditorState({
                    hasEdited: false,
                  });
                }

                parentElement?.classList.remove(
                  "editor-confirmation__container-open"
                );
              }}
            >
              Discard changes
            </button>

            <button
              style={{
                backgroundColor: "#359e3f",
                color: "white",
                border: "none",
                borderRadius: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                const parentElement = document.getElementsByClassName(
                  "editor-confirmation__container__save"
                )[0];

                const pageId = parentElement?.getAttribute("page-id");
                const templateId = parentElement?.getAttribute("template-id");
                setSaving(1);
                let updatedComponents;
                let updatedComponentsArray: any = [];
                let updatedWebpageComponentsArray: any = [];

                StructureAPI.loadWebpageComponents(Number(webpage_id!))
                  .then((result) => {
                    updatedComponentsArray = getUpdatedComponents(
                      result,
                      structure
                    );
                    if (structure && structure.webpageComponents) {
                      try {
                        updatedWebpageComponentsArray =
                          getUpdatedWebpageComponents(
                            result.webpageComponents,
                            structure.webpageComponents
                          );
                      } catch (error) {
                        console.error(
                          "Error in getUpdatedWebpageComponents:",
                          error
                        );

                        const errorUpdatedWebpageComponents = {
                          name: "getUpdateWebpageComponents",
                          message: error,
                          stack:
                            "Error in getUpdatedWebpageComponents Editor.tsx " +
                            error,
                          componentStack: location.pathname,
                          href: window.location.href,
                        };
                        logErrorToBackend(errorUpdatedWebpageComponents);

                        setSaving(0);
                        updateEditorState({
                          hasEdited: false,
                        });
                        return;
                      }
                    } else {
                      console.log(
                        "No webpageComponents found in current structure"
                      );
                    }
                  })
                  .then(() => {
                    updatedComponents = {
                      webpageComponents: updatedWebpageComponentsArray,
                      components: {},
                    };

                    updatedComponentsArray.forEach((component) => {
                      updatedComponents.components[component.id] = {
                        ...component,
                      };
                    });
                  })
                  .finally(() => {
                    StructureAPI.updateWebpageComponents(
                      webpage_id,
                      updatedComponents
                    )
                      .then(() => {
                        parentElement?.classList.remove(
                          "editor-confirmation__container-open"
                        );
                        if (pageId) {
                          setActivePage(Number(pageId));
                          updateEditorState({
                            hasEdited: false,
                          });
                        } else if (templateId) {
                          updateEditorState({
                            hasEdited: false,
                          });
                        }
                      })
                      .catch((er) => {})
                      .finally(() => {
                        setSaving(0);
                      });
                  });
              }}
            >
              Save changes
              {/* Add spinner, when saving === 1 */}
              {saving === 1 ? (
                <Spinner
                  style={{
                    color: "white",
                    width: "15px",
                    height: "15px",
                    marginLeft: "10px",
                  }}
                />
              ) : null}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
