import React from "react";

// Icons
import HomeIcon from "@mui/icons-material/Home";
import TuneIcon from "@mui/icons-material/Tune";
import {
  SpaceBar,
  Language,
  TableRows,
  AnchorOutlined,
} from "@mui/icons-material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import LinkIcon from "@mui/icons-material/Link";
import FormatColorTextIcon from "@mui/icons-material/FormatColorTextOutlined";
import SelectAllOutlinedIcon from "@mui/icons-material/SelectAllOutlined";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import WidthNormalIcon from "@mui/icons-material/WidthNormal";
import FitScreenOutlinedIcon from "@mui/icons-material/FitScreenOutlined";
import BorderStyleOutlinedIcon from "@mui/icons-material/BorderStyleOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import Settings from "@mui/icons-material/Settings";

interface Props {
  componentType: string;
  activeMenu: string | null;
  setMenu: (item: string) => void;
}

export default function SelectEditorMenu({
  componentType,
  activeMenu,
  setMenu,
}: Props) {


  return (
    <>
      {["nav-item", "Button", "logo", "image", "col"].includes(
        componentType
      ) && (
        <div
          className={`global-editor global-editor__top-menu__item  ${
            activeMenu == "linkMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("linkMenu")}
        >
          <LinkIcon />
        </div>
      )}

      {componentType === "product-slider" && (
        <div
          className={`global-editor global-editor__top-menu__item  ${
            activeMenu == "productsSliderMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("productsSliderMenu")}
        >
          <Settings />
        </div>
      )}

      {componentType === "Checkout" && (
        <div
          className={`global-editor global-editor__top-menu__item  ${
            activeMenu == "productsSliderMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("checkoutMenu")}
        >
          <Settings />
        </div>
      )}

      {componentType === "SingleProductComponent" && (
        <div
          className={`global-editor global-editor__top-menu__item  ${
            activeMenu == "singleProductMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("singleProductMenu")}
        >
          <Settings />
        </div>
      )}

      {componentType === "header" && (
        <div
          className={`global-editor global-editor__top-menu__item  ${
            activeMenu == "logoMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("logoMenu")}
        >
          <HomeIcon />
        </div>
      )}

      {componentType === "ProductsComponent" && (
        <>
          <div
            className={`global-editor global-editor__top-menu__item  ${
              activeMenu == "productsMenu"
                ? "global-editor__top-menu__item--active"
                : ""
            }`}
            onClick={() => setMenu("productsMenu")}
          >
            <TuneIcon />
          </div>
        </>
      )}

      {componentType === "header" && (
        <div
          className={`global-editor global-editor__top-menu__item  ${
            activeMenu == "cartMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("headerMenu")}
        >
          <SpaceBar />
        </div>
      )}

      {componentType === "language-select" && (
        <div
          className={`global-editor global-editor__top-menu__item  ${
            activeMenu == "menuMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("languageMenu")}
        >
          <Language />
        </div>
      )}

      {componentType === "navigation-container" && (
        <div
          className={`global-editor global-editor__top-menu__item  ${
            activeMenu == "menuMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("menuMenu")}
        >
          <MenuIcon />
        </div>
      )}
      {/* {componentType === "header" && (
        <div
          className={`global-editor global-editor__top-menu__item  ${
            activeMenu == "cartMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("cartMenu")}
        >
          <ShoppingCartIcon />
        </div>
      )} */}
      {["cart"].includes(componentType) && (
        <div
          className={`global-editor global-editor__top-menu__item ${
            activeMenu == "cartFontMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("cartFontMenu")}
        >
          <FormatColorTextIcon />
        </div>
      )}

      {["language-select"].includes(componentType) && (
        <div
          className={`global-editor global-editor__top-menu__item ${
            activeMenu == "languageFontMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("languageFontMenu")}
        >
          <FormatColorTextIcon />
        </div>
      )}

      {[""].includes(componentType) && (
        <div
          className={`global-editor global-editor__top-menu__item ${
            activeMenu == "fontMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("fontMenu")}
        >
          <FormatColorTextIcon />
        </div>
      )}

      {(componentType === "Button" ||
        componentType === "image" ||
        componentType === "Accordion") && (
        <div
          className={`global-editor global-editor__top-menu__item ${
            activeMenu == "fontMenu"
              ? "global-editor__top-menu__item--active"
              : ""
          }`}
          onClick={() => setMenu("buttonMenu")}
        >
          <WidthNormalIcon />
        </div>
      )}

      {componentType === "col" && (
        <div
          className={
            "global-editor global-editor__top-menu__item" +
            (activeMenu === "colMenu"
              ? " global-editor__top-menu__item--active"
              : "")
          }
          onClick={() => setMenu("colMenu")}
        >
          <ViewColumnIcon />
        </div>
      )}

      {componentType === "Accordion" && (
        <div
          className={
            "global-editor global-editor__top-menu__item" +
            (activeMenu === "colMenu"
              ? " global-editor__top-menu__item--active"
              : "")
          }
          onClick={() => setMenu("accordionMenu")}
        >
          <TableRows />
        </div>
      )}

      <div
        className={
          "global-editor global-editor__top-menu__item" +
          (activeMenu === "spacingMenu"
            ? " global-editor__top-menu__item--active"
            : "")
        }
        onClick={() => setMenu("spacingMenu")}
      >
        <FitScreenOutlinedIcon />
      </div>
      <div
        className={
          "global-editor global-editor__top-menu__item" +
          (activeMenu === "borderMenu"
            ? " global-editor__top-menu__item--active"
            : "")
        }
        onClick={() => setMenu("borderMenu")}
      >
        <BorderStyleOutlinedIcon className="active" />
      </div>
      {componentType === "logo" && (
        <div
          className={
            "global-editor global-editor__top-menu__item" +
            (activeMenu === "logoBgMenu"
              ? " global-editor__top-menu__item--active"
              : "")
          }
          onClick={() => setMenu("logoBgMenu")}
        >
          <ImageOutlinedIcon />
        </div>
      )}
      {!["logo", "image"].includes(componentType) && (
        <div
          className={
            "global-editor global-editor__top-menu__item" +
            (activeMenu === "backgroundMenu"
              ? " global-editor__top-menu__item--active"
              : "")
          }
          onClick={() => setMenu("backgroundMenu")}
        >
          <ImageOutlinedIcon />
        </div>
      )}

      {componentType === "image" && (
        <div
          className={
            "global-editor global-editor__top-menu__item" +
            (activeMenu === "backgroundMenu"
              ? " global-editor__top-menu__item--active"
              : "")
          }
          onClick={() => setMenu("backgroundMenu")}
        >
          <ImageOutlinedIcon />
        </div>
      )}

      {(componentType === "ProductDetailComponent" ||
        componentType === "MediaSlider") && (
        <div
          className={
            "global-editor global-editor__top-menu__item" +
            (activeMenu === "productSliderMenu"
              ? " global-editor__top-menu__item--active"
              : "")
          }
          onClick={() => setMenu("productSliderMenu")}
        >
          <TuneIcon />
        </div>
      )}

      {componentType === "text" && (
        <div
          className={
            "global-editor global-editor__top-menu__item" +
            (activeMenu === "textShadowMenu"
              ? " global-editor__top-menu__item--active"
              : "")
          }
          onClick={() => setMenu("textShadowMenu")}
        >
          <SelectAllOutlinedIcon />
        </div>
      )}

      {componentType === "row" && (
        <div
          className={
            "global-editor global-editor__top-menu__item" +
            (activeMenu === "anchorMenu"
              ? " global-editor__top-menu__item--active"
              : "")
          }
          onClick={() => setMenu("anchorMenu")}
        >
          <AnchorOutlined />
        </div>
      )}
    </>
  );
}
