import React, { useState, useRef, useEffect, memo, Suspense } from "react";
import { useLayoutEffect } from "react";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { isEqual, cloneDeep } from "lodash";

import {
  computePosition,
  flip,
  shift,
  autoPlacement,
  autoUpdate,
} from "@floating-ui/react";

import {
  findComponentById,
  getComponentType,
  inClass,
} from "../../../../utils/Helpers";
import {
  AnchorOutlined,
  Language,
  MoreHorizOutlined,
  SpaceBar,
  TableRows,
} from "@mui/icons-material";

import Confirmation from "../Confirmation/Confirmation";
import CreateTemplateModal from "../Modals/CreateTemplateModal";
import { setEditableComponentId } from "../../../../store/reducers/textEditor/textEditorActions";
import SelectElementType from "./SelectElementType/SelectElementType";
import SelectTemplate from "./SelectTemplate/SelectTemplate";
import WidthWideOutlinedIcon from "@mui/icons-material/WidthWideOutlined";
import AddIcon from "@mui/icons-material/Add";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StructureAPI from "../../../../store/reducers/builderReducers/structure/structureApi";
import {
  updateStructureComponent,
  addNewComponent,
  deleteComponentById,
  removeChildFromParent,
  addChildToParent,
  updateComponentStructureOrder,
  updateStructureWebpageComponentsOrder,
  setStructure,
} from "../../../../store/reducers/builderReducers/structure/structureActions";
import { useAppSelector } from "../../../../store/hooks";
import { AllComponents } from "../../../_default/interfaces/base";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Dropdown from "react-overlays/Dropdown";
import { createPortal } from "react-dom";
import { Spinner } from "react-bootstrap";
import TemplateAPI from "../../../../store/reducers/builderReducers/templates/templateApi";
import TextEditorComponent from "./TextEditorComponent/TextEditorComponent";
import SelectEditorMenu from "./SelectEditorMenu/SelectEditorMenu";
import BottomMenu from "./BottomMenu/BottomMenu";

// Drag and drop

import { handleMouseDown } from "./DragNDrop/handleMouseDown";
import { handleMouseUp } from "./DragNDrop/handleMouseUp";
import { handleDragStart } from "./DragNDrop/handleDragStart";
import { handleDragEnd } from "./DragNDrop/handleDragEnd";
import { handleDragOverPos } from "./DragNDrop/handleDragOverPos";
import { handleDragLeavePos } from "./DragNDrop/handleDragLeavePos";
import { updateEditorState } from "../../../../store/reducers/builderReducers/editor/editorActions";
import {
  getUpdatedComponents,
  getUpdatedWebpageComponents,
  logErrorToBackend,
} from "../../../_default/utilities/helpers";

function Editor({
  id,
  parent_id,
  keyDownEvent,
}: {
  id: number;
  parent_id: number;
  keyDownEvent: KeyboardEvent;
}) {
  const editorId = id;
  id = parent_id;
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )!;

  const editor = useAppSelector((state) => state.builder.editor.editor);
  const editorState = useAppSelector((state) => state.builder.editor);
  const [isLoading, setIsLoading] = useState(false);
  const parentComponent = useAppSelector(
    (state) =>
      state.builder.structure.components[editorState.component_parent_id ?? ""]
  );

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [createComponentTemplate, setCreateComponentTemplate] =
    useState<AllComponents | null>(null);
  const [showConfirmTemplateUnlink, setShowConfirmTemplateUnlink] =
    useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [onMouseUp, setOnMouseUp] = useState<MouseEvent>();
  const website = useAppSelector((state) => state.builder.website);
  const [builderDragElement, setBuilderDragElement] =
    useState<HTMLElement | null>(null);
  const webpage_id = useAppSelector((state) => state.builder.pages.active);
  const structure = useAppSelector((state) => state.builder.structure);
  const templates = useAppSelector((state) => state.builder.templates);
  const template = templates.find(
    (x: any) => x.webpage_component_id === component.id
  );
  const templateId = template ? template.id : null;
  function unlinkTemplate() {
    // duplicate element
    duplicateComponent(component);
    // remove existing component
    deleteComponent(component);

    TemplateAPI.deleteTemplate(templateId!);
  }

  const componentType = getComponentType(component);
  const parentComponentType = parentComponent
    ? getComponentType(parentComponent)
    : "";

  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  const [range, saveRange] = useState<Range>();
  const [editorPos, setEditorPos] = useState<number>(28);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowDropdown(false);
  }, [activeMenu]);

  useEffect(() => {
    /**
     * if clicked on outside of element
     */

    function handleClickOutsideBuilderElement(event: any) {
      const builderElement = inClass("builder-component", ref.current!);

      if (builderElement && !builderElement?.contains(event.target)) {
        if (
          ["text", "Button", "nav-item"].includes(component.type) &&
          builderElement &&
          builderElement!.querySelector("[contenteditable=true]") &&
          // @ts-ignore
          component.attributes.text !==
            builderElement.querySelector("[contenteditable=true]")!.innerHTML
        ) {
          updateStructureComponent(
            id,
            "attributes.text",
            builderElement!.querySelector("[contenteditable=true]")!.innerHTML
          );
        }
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutsideBuilderElement);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutsideBuilderElement);
    };
  }, [ref]);

  // useLayoutEffect(() => {
  //   setEditorPos(ref.current?.clientHeight ?? 24);
  //   const resizeListener = () => {
  //     setEditorPos(ref.current?.clientHeight ?? 24);
  //   };
  //   window.addEventListener("resize", resizeListener);
  //   return () => window.removeEventListener("resize", resizeListener);
  // });

  useEffect(() => {
    const resizeListener = () => {
      setEditorPos(ref.current?.clientHeight ?? 24);
    };
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [editor]);

  function triggerResize() {
    setTimeout(() => {
      setEditorPos(ref.current?.clientHeight ?? 24);
    }, 1);
  }

  useEffect(() => {
    function mouseupEvent(e: any) {
      if (
        inClass("builder-component--active", e.target) &&
        !inClass("global-editor__menu", e.target)
      ) {
        setOnMouseUp(e);
        var sel = window.getSelection && window.getSelection();
        if (sel && sel.rangeCount > 0) {
          saveRange(window.getSelection()?.getRangeAt(0));
        }
      }
    }
    window.addEventListener("mouseup", mouseupEvent);

    return () => {
      window.removeEventListener("mouseup", mouseupEvent);
    };
  }, []);

  function setRange(range_ = range) {
    let selection = window.getSelection();
    selection?.removeAllRanges();
    if (range_) {
      selection?.addRange(range_);
    }
  }

  function setMenu(menu: string) {
    if (activeMenu === menu) {
      setActiveMenu(null);
    } else {
      //setActiveMenu(menu);
      setActiveMenu(menu);
    }
  }

  function deleteComponent(component: AllComponents) {
    setIsLoading(true);
    StructureAPI.deleteComponent(component.webpage_id, component).then((x) => {
      if (!x.hasOwnProperty("message")) setEditableComponentId(null);

      setIsLoading(false);
    });
  }

  function duplicateComponent(component: AllComponents) {
    setIsLoading(true);

    let updatedComponents;

    let updatedComponentsArray: any = [];
    let updatedWebpageComponentsArray: any = [];

    StructureAPI.loadWebpageComponents(Number(webpage_id!))
      .then((result) => {
        updatedComponentsArray = getUpdatedComponents(result, structure);
        if (structure && structure.webpageComponents) {
          try {
            updatedWebpageComponentsArray = getUpdatedWebpageComponents(
              result.webpageComponents,
              structure.webpageComponents
            );
          } catch (error) {
            console.error("Error in getUpdatedWebpageComponents:", error);

            const errorUpdatedWebpageComponents = {
              name: "getUpdateWebpageComponents",
              message: error,
              stack: "Error in getUpdatedWebpageComponents Editor.tsx " + error,
              componentStack: location.pathname,
              href: window.location.href,
            };
            logErrorToBackend(errorUpdatedWebpageComponents);

            setIsLoading(false);
            updateEditorState({
              hasEdited: false,
            });
            return;
          }
        } else {
          console.log("No webpageComponents found in current structure");
        }
      })
      .then(() => {
        updatedComponents = {
          webpageComponents: updatedWebpageComponentsArray,
          components: {},
        };

        updatedComponentsArray.forEach((component) => {
          updatedComponents.components[component.id] = {
            ...component,
          };
        });
      })
      .finally(() => {
        StructureAPI.updateWebpageComponents(
          webpage_id,
          updatedComponents
        ).then(() => {
          StructureAPI.duplicateComponent(component.webpage_id, component).then(
            (x) => {
              StructureAPI.loadWebpageComponents(webpage_id!)
                .then((x) => {
                  setStructure(x);
                  setIsLoading(false);
                })
                .then(() => {
                  deleteComponentById(editorId);
                  addNewComponent({
                    id: editorId,
                    type: "editor",
                    sort_order: -1,
                    parent_id: x.payload[Object.keys(x.payload)[0]].id,
                    childElements: [],
                  } as any);
                  setEditableComponentId(
                    x.payload[Object.keys(x.payload)[0]].id
                  );
                });
            }
          );
        });
      });
  }

  // editorDraghandle
  // const handle = document.getElementById('handle');
  // handle.onmousedown = function(e) {
  //     e.target.parentNode.setAttribute('draggable', 'true')
  // };

  // componentDidMount() {
  // When the component is mounted, add your DOM listener to the "nv" elem.
  // (The "nv" elem is assigned in the render function.)
  // this.nv.addEventListener("nv-enter", this.handleNvEnter);
  // }

  // componentWillUnmount() {
  // Make sure to remove the DOM listener when the component is unmounted.
  // this.nv.removeEventListener("nv-enter", this.handleNvEnter);
  // }

  // https://stackoverflow.com/questions/73251435/drag-and-drop-cells-on-css-grid-only-works-properly-when-moving-a-cell-to-the-ri

  useLayoutEffect(() => {
    if (activeMenu) {
      triggerResize();
    }
  }, [activeMenu, triggerResize]);

  useEffect(() => {
    const editorDraghandle = document.querySelector(".editorDraghandle");

    // Needed variable for contentComponent drag and drop -> needs refactor
    let isText = false;

    const onMouseDown = (e) => {
      handleMouseDown(e, ref, isText);

      // const builderElement = inClass("builder-component--active", ref.current!);
      // builderElement?.setAttribute("draggable", "true");

      // if (e.target.nodeName === "#text") {
      //   isText = true;
      //   return;
      // }

      // // Allow scrolling on draggable element

      // // const editorState = useAppSelector((state) => state.builder.editor);

      // // const parentComponent = useAppSelector(
      // //   (state) =>
      // //     state.builder.structure.components[editorState.component_parent_id ?? ""]
      // // );
    };

    const onMouseUp = (e) => {
      handleMouseUp(e, ref, isText);

      // const builderElement = inClass("builder-component--active", ref.current!);
      // builderElement?.setAttribute("draggable", "false");
    };

    const onDragStart = (e) => {
      handleDragStart(e, ref, isText);

      // const builderElement = inClass("builder-component--active", ref.current!);

      // if (e.target.nodeName === "#text") {
      //   isText = true;
      //   return;
      // }

      // if (
      //   !inClass("builder-component", builderElement?.parentElement!) &&
      //   e.target.classList.contains("row")
      // ) {
      //   e.target.setAttribute("data-drag-type", "main");

      //   setBuilderDragElement(e.target);
      // } else {
      //   // Get the dragged element parent id
      //   e.dataTransfer.setData(
      //     "id",
      //     inClass("builder-component", builderElement?.parentElement!)!.id
      //   );
      // }

      // e.dataTransfer.effectAllowed = "move";
    };

    const onDragEnd = (e) => {
      handleDragEnd(e, ref);

      // const builderElement = inClass("builder-component--active", ref.current!);
      // builderElement?.setAttribute("draggable", "false");
      // let dropTargets = Array.from(
      //   document.querySelectorAll(".builder-component")
      // );
      // dropTargets.forEach((dropTarget) => {
      //   dropTarget.classList.remove("dragover");
      // });
    };

    const handleDragEnter = (e) => {
      e.preventDefault();
      // e.target.classList.add("dragover");

      e.target.addEventListener("dragover", handleDragOverPosition);

      // handleSortingPosition(e);
    };

    const handleDragOverPosition = (e) => {
      e.preventDefault();
      handleDragOverPos(e, ref, isText);
    };

    const handleDragLeave = (e) => {
      handleDragLeavePos(e, ref);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.dataTransfer!.dropEffect = "move";
      return false;
    };

    const ondrop = (e) => {
      const builderElement = inClass("builder-component--active", ref.current!);

      e.preventDefault();
      e.stopPropagation();

      // Remove the styles that were left on the target
      e.target.style.webkitBoxShadow = "";
      e.target.style.mozBoxShadow = "";
      e.target.style.boxShadow = "";

      if (e.target.offsetParent && e.target.offsetParent.style) {
        e.target.offsetParent.style.webkitBoxShadow = "";
        e.target.offsetParent.style.mozBoxShadow = "";
        e.target.offsetParent.style.boxShadow = "";
      }

      if (e.target.firstChild && e.target.firstChild.style) {
        e.target.firstChild.style.webkitBoxShadow = "";
        e.target.firstChild.style.mozBoxShadow = "";
        e.target.firstChild.style.boxShadow = "";
        e.target.firstChild.transition = `none`;
      }

      let parent_id = e.target.getAttribute("id");
      // const builderElement = builderElement?.getATtribute('data-component-type');

      if (!builderElement) {
        return;
      }

      if (builderElement.getAttribute("data-component-type") === "section") {
        if (builderElement.getAttribute("data-drag-type") === "main") {
          if (
            e.target.getAttribute("data-component-type") === "section" &&
            e.target.offsetParent &&
            !e.target.offsetParent.classList.contains("builder-component")
          ) {
            const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
            const destinationId = e.target.getAttribute("id"); // ID of the element, which we dragged on
            const dropLocation = builderElement.getAttribute("data-position");

            updateStructureWebpageComponentsOrder(
              draggedComponentId,
              destinationId,
              dropLocation
            );
          }
        } else if (
          builderElement.getAttribute("data-drag-type") !== "main" &&
          builderElement.getAttribute("data-drag-type") !== "inner-section"
        ) {
          if (
            e.target.getAttribute("data-component-type") === "section" &&
            e.target.offsetParent.classList.contains("builder-component") &&
            (e.target.offsetParent.classList.contains("row") ||
              e.target.offsetParent.classList.contains("inner-section") ||
              e.target.offsetParent.classList.contains("builder-footer")) &&
            builderElement.id !== e.target.id &&
            e.target.offsetParent.offsetParent?.id !== builderElement.id
          ) {
            const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
            const destinationId = e.target.getAttribute("id"); // ID of the element, which we dragged on
            const dropLocation = builderElement.getAttribute("data-position");

            updateComponentStructureOrder(
              parentComponent.id,
              draggedComponentId,
              destinationId,

              dropLocation,
              true
            );
            deleteComponentById(editorId);
            setEditableComponentId(null);
          } else if (
            e.target.getAttribute("data-component-type") === "section" &&
            e.target.classList.contains("builder-component") &&
            e.target.classList.contains("row") &&
            !e.target.offsetParent?.classList.contains("builder-component") &&
            !builderElement.offsetParent?.classList.contains("col") &&
            builderElement?.offsetParent?.id !== e.target?.id &&
            builderElement.id !== e.target.id
          ) {
            const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
            const destinationId = e.target.getAttribute("id"); // ID of the element, which we dragged on
            const dropLocation = builderElement.getAttribute("data-position");

            updateComponentStructureOrder(
              parentComponent ? parentComponent?.id : component.parent_id,
              draggedComponentId,
              destinationId,

              dropLocation,
              true
            );

            deleteComponentById(editorId);
            setEditableComponentId(null);
          } else if (
            e.target.getAttribute("data-component-type") === "inner-section" &&
            e.target.classList.contains("builder-component") &&
            e.target.offsetParent.classList.contains("col") &&
            builderElement?.offsetParent !== e.target.id &&
            builderElement.id !== e.target.id
          ) {
            const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
            const destinationId = e.target.getAttribute("id"); // ID of the element, which we dragged on
            const dropLocation = builderElement.getAttribute("data-position");

            updateComponentStructureOrder(
              parentComponent ? parentComponent?.id : component.parent_id,
              draggedComponentId,
              destinationId,
              dropLocation,
              true
            );
          } else if (
            e.target.getAttribute("data-component-type") === "footer" &&
            e.target.classList.contains("builder-component") &&
            !e.target.offsetParent.classList.contains("builder-component") &&
            !builderElement.offsetParent?.classList.contains("col") &&
            !e.target.offsetParent.classList.contains("inner-section") &&
            builderElement?.offsetParent?.id !== e.target.id &&
            builderElement.id !== e.target.id &&
            builderElement.getAttribute("data-component-type") !==
              "inner-section"
          ) {
            const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
            const destinationId = e.target.getAttribute("id"); // ID of the element, which we dragged on
            const dropLocation = builderElement.getAttribute("data-position");

            updateComponentStructureOrder(
              parentComponent ? parentComponent?.id : component.parent_id,
              draggedComponentId,
              destinationId,

              dropLocation,
              true
            );

            deleteComponentById(editorId);
            setEditableComponentId(null);
          }
        }
      }

      if (
        builderElement.getAttribute("data-component-type") === "inner-section"
      ) {
        if (
          e.target.getAttribute("data-component-type") === "section" &&
          e.target.offsetParent.classList.contains("builder-component") &&
          !e.target.offsetParent.classList.contains("inner-section") &&
          e.target !== builderElement.offsetParent
        ) {
          const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
          let destinationId = e.target.getAttribute("id"); // ID of the element, which we dragged on
          const dropLocation = builderElement.getAttribute("data-position");

          if (!destinationId) {
            destinationId = inClass("builder-component", e.target)!.id;
          }

          updateComponentStructureOrder(
            parentComponent ? parentComponent?.id : component.parent_id,
            draggedComponentId,
            destinationId,
            dropLocation,
            false
          );
          deleteComponentById(editorId);
          setEditableComponentId(null);
        } else if (
          e.target.offsetParent.classList.contains("builder-component") &&
          !e.target.offsetParent.classList.contains("inner-section") &&
          e.target.offsetParent.classList.contains("col") &&
          !e.target.offsetParent?.offsetParent.classList.contains(
            "inner-section"
          )
        ) {
          const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
          let destinationId = e.target.getAttribute("id"); // ID of the element, which we dragged on
          const dropLocation = builderElement.getAttribute("data-position");

          if (!destinationId) {
            destinationId = inClass("builder-component", e.target)!.id;
          }

          updateComponentStructureOrder(
            component.type === "image"
              ? component.parent_id
              : parentComponent.id,
            draggedComponentId,
            destinationId,
            dropLocation,
            false
          );
          deleteComponentById(editorId);
          setEditableComponentId(null);
        }
      }

      if (
        builderElement.getAttribute("data-component-type") !== "section" &&
        builderElement.getAttribute("data-component-type") !== "inner-section"
      ) {
        if (
          e.target.getAttribute("data-component-type") === "section" &&
          e.target.offsetParent.classList.contains("builder-component")
        ) {
          const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
          let destinationId = e.target.getAttribute("id"); // ID of the element, which we dragged on
          const dropLocation = builderElement.getAttribute("data-position");

          if (!destinationId) {
            destinationId = inClass("builder-component", e.target)!.id;
          }

          updateComponentStructureOrder(
            component.type === "image"
              ? component.parent_id
              : parentComponent.id,

            draggedComponentId,
            destinationId,
            dropLocation,
            false
          );
          deleteComponentById(editorId);
          setEditableComponentId(null);
        } else if (
          e.target.getAttribute("data-component-type") !== "section" &&
          e.target.offsetParent?.classList.contains("builder-component") &&
          !e.target.classList.contains("global-editor") &&
          e.target.offsetParent.id !== builderElement.id &&
          e.target.id !== builderElement.id
        ) {
          const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
          let destinationId = e.target.getAttribute("id"); // ID of the element, which we dragged on
          const dropLocation = builderElement.getAttribute("data-position");

          if (!destinationId) {
            destinationId = inClass("builder-component", e.target)!.id;
          }

          updateComponentStructureOrder(
            component.type === "image"
              ? component.parent_id
              : parentComponent.id,
            draggedComponentId,
            destinationId,
            dropLocation,
            false
          );
          deleteComponentById(editorId);
          setEditableComponentId(null);
        }
      }
    };

    const ondragover = (e) => {
      e.preventDefault();
    };

    const builderElement = inClass("builder-component--active", ref.current!);
    // Editor Event Listeners
    editorDraghandle?.addEventListener("mousedown", onMouseDown);
    editorDraghandle?.addEventListener("mouseup", onMouseUp);

    // BuilderElement Event Listeners
    builderElement?.addEventListener("dragstart", onDragStart);
    builderElement?.addEventListener("dragend", onDragEnd);

    // Define drop targets events
    let dropTargets = Array.from(
      document.querySelectorAll(".builder-component")
    );
    dropTargets.forEach((dropTarget) => {
      dropTarget.addEventListener("drop", ondrop);
      dropTarget.addEventListener("dragenter", handleDragEnter);
      dropTarget.addEventListener("dragleave", handleDragLeave);
      dropTarget.addEventListener("dragover", handleDragOver);
    });

    return () => {
      // Unbind the event listeners on cleanup
      document.removeEventListener("dragover", ondragover);
      editorDraghandle?.removeEventListener("mousedown", onMouseDown);
      editorDraghandle?.removeEventListener("mouseup", onMouseUp);
      builderElement?.removeEventListener("dragend", onDragEnd);
      builderElement?.removeEventListener("dragstart", onDragStart);

      // Remove drop targets event listeners
      dropTargets.forEach((dropTarget) => {
        dropTarget.removeEventListener("drop", ondrop);
        dropTarget.removeEventListener("dragenter", handleDragEnter);
        dropTarget.removeEventListener("dragleave", handleDragLeave);
        dropTarget.removeEventListener("dragover", handleDragOver);
      });
    };
  }, [() => ref]);

  // If ROW then different styles

  const tooltip = document.getElementById("tooltip");
  const element = document.getElementById(id.toString());

  if (element && tooltip) {
    computePosition(element, tooltip, {
      placement: "top-start",
      middleware: [flip(), shift({ padding: 0 })],
    }).then(({ x, y }) => {
      Object.assign(tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  useEffect(() => {
    const tooltip = document.getElementById("tooltip");
    const element = document.getElementById(id.toString());

    if (element && tooltip) {
      computePosition(element, tooltip, {
        placement: "top-start",
        middleware: [flip(), shift({ padding: 0 })],
      }).then(({ x, y }) => {
        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    }
  }, [element, tooltip, editorPos, activeMenu]);

  return (
    <>
      <div id="tooltip" role="tooltip" style={{ zIndex: 9998 }}>
        {editor && (
          <>
            <TextEditorComponent id={id} />
          </>
        )}

        {[
          "header",
          "logo",
          "nav",
          "cart",
          "language-select",
          "section",
        ].includes(componentType) && (
          <div style={{ marginTop: editorPos + "px" }}></div>
        )}
        <div
          id={editorId ? editorId.toString() : ""}
          ref={ref}
          className={"global-editor global-editor__menu"}
          style={{
            top: [
              "row",
              "logo",
              "nav",
              "cart",
              "header",
              "section",
              "nav-item",
              "language-select",
            ].includes(componentType)
              ? "0px"
              : "-" + editorPos + "px",
            width: "auto",
          }}
        ></div>

        <div className="global-editor global-editor__top-menu">
          <div className="col-12 d-flex flex-nowrap">
            {!["header", "logo", "nav", "footer"].includes(componentType) &&
              parentComponentType !== "Accordion" && (
                <div
                  className="global-editor global-editor__top-menu__item editorDraghandle"
                  style={{ cursor: "grab" }}
                >
                  <SwapVertIcon />
                </div>
              )}

            <SelectEditorMenu
              componentType={componentType}
              activeMenu={activeMenu}
              setMenu={setMenu}
            />

            {(componentType === "row" || componentType === "inner_section") &&
              !inClass(
                "builder-footer",
                document.getElementById(id.toString())!
              ) && (
                <div
                  className={
                    "global-editor global-editor__top-menu__item" +
                    (activeMenu === "contentWidthMenu"
                      ? " global-editor__top-menu__item--active"
                      : "")
                  }
                  onClick={() => {
                    setShowDropdown(false);
                    setMenu("contentWidthMenu");
                  }}
                >
                  <WidthWideOutlinedIcon />
                </div>
              )}

            {parentComponentType === "footer" && (
              <div
                className={
                  "global-editor global-editor__top-menu__item" +
                  (activeMenu === "contentWidthMenu"
                    ? " global-editor__top-menu__item--active"
                    : "")
                }
                onClick={() => {
                  setShowDropdown(false);
                  setMenu("contentWidthMenu");
                }}
              >
                <WidthWideOutlinedIcon />
              </div>
            )}

            {template && (
              <div
                className={"global-editor global-editor__top-menu__item"}
                onClick={() => {
                  setShowDropdown(false);
                  setCreateComponentTemplate(component);
                }}
              >
                <DashboardIcon />
              </div>
            )}

            {!["logo", "nav"].includes(componentType) && (
              <div className="global-editor global-editor__top-menu__item flex-grow-1 text-end position-relative">
                <MoreHorizOutlined
                  data-bs-toggle="dropdown"
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                  }}
                />
                <ul
                  style={{ top: "30px" }}
                  className={
                    "dropdown-menu b-dropdown-menu dropdown-menu-end builder-dropdown " +
                    (showDropdown ? "show" : "")
                  }
                >
                  {(!["row", "header"].includes(componentType) ||
                    (componentType === "row" &&
                      !inClass(
                        "builder-footer",
                        document.getElementById(id.toString())!
                      ))) && (
                    <li>
                      <span
                        style={{
                          cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                        className="dropdown-item builder-dropdown__item"
                        onClick={() => {
                          if (isLoading) {
                            return;
                          }

                          duplicateComponent(component);
                        }}
                      >
                        Create a copy
                        {isLoading && (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{ marginLeft: "5px" }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </span>
                    </li>
                  )}

                  {(!["row", "header", "footer"].includes(componentType) ||
                    (componentType === "row" &&
                      !inClass(
                        "builder-footer",
                        document.getElementById(id.toString())!
                      ))) && (
                    <>
                      {!templateId ? (
                        <li>
                          <span
                            style={{
                              cursor: isLoading ? "not-allowed" : "pointer",
                            }}
                            className="dropdown-item builder-dropdown__item"
                            onClick={() => {
                              if (isLoading) {
                                return;
                              }

                              setShowDropdown(!showDropdown);
                              setCreateComponentTemplate(component);
                            }}
                          >
                            Save template
                          </span>
                        </li>
                      ) : (
                        <li>
                          <span
                            style={{
                              cursor: isLoading ? "not-allowed" : "pointer",
                            }}
                            className="dropdown-item builder-dropdown__item"
                            onClick={() => {
                              if (isLoading) {
                                return;
                              }

                              setShowConfirmTemplateUnlink(true);
                            }}
                          >
                            Unlink template
                          </span>
                        </li>
                      )}
                    </>
                  )}
                  <li>
                    <span
                      style={{
                        cursor: isLoading ? "not-allowed" : "pointer",
                      }}
                      className="dropdown-item builder-dropdown__item"
                      onClick={() => {
                        if (isLoading) {
                          return;
                        }
                        setShowConfirmation(true);
                      }}
                    >
                      Remove
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        {activeMenu && (
          <div className="global-editor global-editor__bottom-menu b-p-1">
            <BottomMenu
              id={id}
              activeMenu={activeMenu}
              triggerResize={triggerResize}
              onMouseUp={onMouseUp}
              setRange={setRange}
              keyDownEvent={keyDownEvent}
            />
          </div>
        )}
        {showConfirmation && (
          <Confirmation
            id={id.toString()}
            onCancel={() => {
              setShowConfirmation(false);
            }}
            onConfirm={() => {
              // if (componentType === "row" && inClass("builder-footer", document.getElementById(id))) {
              //   deleteComponent(parseInt(document.getElementsByClassName("builder-footer")[0].id))
              // } else {
              deleteComponent(component);
              // }
            }}
            text="Are you sure you want to remove this element?"
            isLoading={isLoading}
          />
        )}
        {showConfirmTemplateUnlink && (
          <Confirmation
            id={id.toString()}
            onCancel={() => {
              setShowConfirmTemplateUnlink(false);
            }}
            onConfirm={() => {
              unlinkTemplate();
            }}
            confirmText="Unlink"
            text="Are you sure you want to unlink this element from template? After nulinking, changing this element will not change the template."
          />
        )}
        {createComponentTemplate && (
          <>
            <CreateTemplateModal
              createComponentTemplate={createComponentTemplate}
              template={template}
              setCreateComponentTemplate={setCreateComponentTemplate}
            />
          </>
        )}
      </div>

      <div className="add-new-element-buttons">
        <div className="p-0">
          {(componentType === "col" ||
            componentType === "nav" ||
            componentType === "navigation-container") && (
            <Dropdown onToggle={() => {}} drop="up">
              <Dropdown.Toggle>
                {(props) => (
                  <span
                    {...props}
                    className="add-new-element-button elementB inline-block"
                  >
                    <AddIcon />
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu offset={[0, -19]}>
                {(props, { show }) =>
                  createPortal(
                    <div {...props} className="builder-dropdown">
                      <SelectElementType component={component} />
                    </div>,
                    document.body
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
          )}
          {(componentType === "row" ||
            componentType === "inner_section" ||
            componentType === "footer" ||
            componentType === "Accordion") && (
            <div
              className="add-new-element-button colB"
              style={{ display: "inline-block" }}
              onClick={() =>
                StructureAPI.addComponent(component.webpage_id, {
                  type:
                    componentType === "inner_section" ||
                    componentType === "footer" ||
                    componentType === "Accordion"
                      ? "section"
                      : component.type,
                  sort_order: component.sort_order + 1,
                  attributes: [
                    { name: "classes", value: "col col-4", type: "attributes" },
                    { name: "paddingTop", value: "60px", type: "styles" },
                    { name: "paddingLeft", value: "60px", type: "styles" },
                    { name: "paddingBottom", value: "60px", type: "styles" },
                    { name: "paddingRight", value: "60px", type: "styles" },
                  ],
                  parent_id: component.id,
                }).then((x) => {
                  deleteComponentById(editorId);
                  addNewComponent({
                    id: editorId,
                    type: "editor",
                    sort_order: 1,
                    parent_id: x.payload[Object.keys(x.payload)[0]].id,
                    childElements: [],
                  } as any);
                  setEditableComponentId(
                    x.payload[Object.keys(x.payload)[0]].id
                  );
                })
              }
            >
              <AddIcon />
            </div>
          )}
          {["col", "row", "inner_section", "Accordion"].includes(
            componentType
          ) && (
            <Dropdown onToggle={() => {}} drop="up">
              <Dropdown.Toggle>
                {(props) => (
                  <span
                    {...props}
                    className={
                      "inline-block add-new-element-button " +
                      (componentType === "col" ? "elementB" : "colB")
                    }
                  >
                    <LibraryAddIcon />
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu offset={[0, -19]}>
                {(props, { show }) =>
                  createPortal(
                    <div {...props} className="builder-dropdown">
                      <SelectTemplate
                        key={"childTemplate"}
                        component={component}
                        sort_order={component.sort_order}
                      />
                    </div>,
                    document.body
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <div className="p-0">
          {["col", "row", "inner_section"].includes(componentType) && (
            <div
              style={{ display: "inline-block" }}
              className={
                "add-new-element-button " +
                (componentType === "col" ? "colB" : "rowB")
              }
              onClick={() => {
                if (componentType === "col") {
                  StructureAPI.addComponent(component.webpage_id, {
                    type: component.type,
                    sort_order: component.sort_order + 1,
                    attributes: [
                      {
                        name: "classes",
                        value: "col col-4",
                        type: "attributes",
                      },
                      { name: "paddingTop", value: "60px", type: "styles" },
                      { name: "paddingLeft", value: "60px", type: "styles" },
                      { name: "paddingBottom", value: "60px", type: "styles" },
                      { name: "paddingRight", value: "60px", type: "styles" },
                    ],
                    parent_id: component.parent_id,
                  }).then((x) => {
                    deleteComponentById(editorId);
                    addNewComponent({
                      id: editorId,
                      type: "editor",
                      sort_order: 1,
                      parent_id: x.payload[Object.keys(x.payload)[0]].id,
                      childElements: [],
                    } as any);
                    setEditableComponentId(
                      x.payload[Object.keys(x.payload)[0]].id
                    );
                  });
                } else if (
                  componentType === "row" ||
                  componentType === "footer"
                ) {
                  StructureAPI.addComponent(component.webpage_id, {
                    type: component.type,
                    sort_order: component.sort_order + 1,
                    attributes: [
                      { name: "classes", value: "row", type: "attributes" },
                      { name: "paddingTop", value: "20px", type: "styles" },
                      { name: "paddingLeft", value: "20px", type: "styles" },
                      { name: "paddingBottom", value: "20px", type: "styles" },
                      { name: "paddingRight", value: "20px", type: "styles" },
                      {
                        name: "content_width_unit",
                        value: website?.content_width_unit,
                        type: "attributes",
                      },
                      {
                        name: "content_width_size",
                        value: website?.content_width_size,
                        type: "attributes",
                      },
                    ],
                    parent_id: component.parent_id,
                    childElements: [
                      {
                        type: "section",
                        sort_order: 1,
                        attributes: [
                          { name: "classes", value: "col", type: "attributes" },
                          { name: "paddingTop", value: "40px", type: "styles" },
                          {
                            name: "paddingLeft",
                            value: "40px",
                            type: "styles",
                          },
                          {
                            name: "paddingBottom",
                            value: "40px",
                            type: "styles",
                          },
                          {
                            name: "paddingRight",
                            value: "40px",
                            type: "styles",
                          },
                        ],
                      },
                    ],
                  }).then((x) => {
                    updateStructureWebpageComponentsOrder(
                      x.payload[Object.keys(x.payload)[0]].id,
                      component.id,
                      "bottom"
                    );

                    deleteComponentById(editorId);
                    addNewComponent({
                      id: editorId,
                      type: "editor",
                      sort_order: 1,
                      parent_id: x.payload[Object.keys(x.payload)[0]].id,
                      childElements: [],
                    } as any);
                    setEditableComponentId(
                      x.payload[Object.keys(x.payload)[0]].id
                    );
                  });
                }
              }}
            >
              <AddIcon />
            </div>
          )}
          {![
            "row",
            "col",
            "inner_section",
            "logo",
            "nav",
            "cart",

            "language-select",
          ].includes(componentType) &&
            parentComponent && (
              <Dropdown onToggle={() => {}} drop="up">
                <Dropdown.Toggle>
                  {(props) => (
                    <span
                      {...props}
                      className="add-new-element-button elementB inline-block"
                    >
                      <AddIcon />
                    </span>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu offset={[0, -19]}>
                  {(props, { show }) =>
                    createPortal(
                      <div {...props} className="builder-dropdown">
                        <SelectElementType component={parentComponent} />
                      </div>,
                      document.body
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>
            )}

          {![
            "header",
            "logo",
            "nav",
            "cart",
            "language-select",
            "nav-item",
          ].includes(componentType) && (
            <Dropdown onToggle={() => {}} drop="up">
              <Dropdown.Toggle>
                {(props: any) => (
                  <span
                    {...props}
                    className={
                      "inline-block add-new-element-button " +
                      (componentType === "col"
                        ? "colB"
                        : componentType === "row"
                        ? "rowB"
                        : "elementB")
                    }
                  >
                    <LibraryAddIcon />
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu offset={[0, -19]}>
                {(props: any, { show: any }) =>
                  createPortal(
                    <div {...props} className="builder-dropdown">
                      <SelectTemplate
                        key={"parentTemplate"}
                        component={parentComponent}
                        sort_order={parentComponent?.sort_order ?? 9}
                      />
                    </div>,
                    document.body
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
          )}

          {![
            "header",
            "footer",
            "logo",
            "nav",
            "cart",

            "language-select",
          ].includes(componentType) && (
            <div
              style={{
                display: "inline-block",
                cursor: isLoading ? "not-allowed" : "pointer",
              }}
              className={
                "add-new-element-button " +
                (componentType === "col"
                  ? "colB"
                  : componentType === "row"
                  ? "rowB"
                  : "elementB")
              }
              onClick={() => {
                if (isLoading) {
                  return;
                }

                duplicateComponent(component);
              }}
            >
              {isLoading ? <Spinner size="sm" /> : <ContentCopyIcon />}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default memo(Editor);
