import StructureAPI from "../../../../../store/reducers/builderReducers/structure/structureApi";
import { useAppSelector } from "../../../../../store/hooks";
import { AllComponents } from "../../../../_default/interfaces/base";
import { useEffect, useState } from "react";
import React from "react";
import {
  addNewComponent,
  deleteComponentById,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { editorId } from "../../../../../pages/Builder";
import { setEditableComponentId } from "../../../../../store/reducers/textEditor/textEditorActions";
import { MoreHorizOutlined } from "@mui/icons-material";
interface Props {
  component: AllComponents;
}

export default function SelectElementType({ component }: Props) {
  const typographies = useAppSelector((state) => state.builder.typographies);
  const website = useAppSelector((state) => state.builder.website);
  const [style, setStyle] = useState<React.CSSProperties>();
  const [expanded, setExpanded] = useState(false);

  const defaultTypography = useAppSelector(
    (state) => state.builder.website.default_typography_id
  );

  let parentElement;

  let elementTypes = [
    { label: "Text", value: "text" },
    { label: "Products", value: "ProductsComponent" },
    { label: "Product", value: "ProductDetailComponent" },
    { label: "Checkout", value: "Checkout" },
    { label: "Button", value: "Button" },
    { label: "Contact Form", value: "form" },
    { label: "Inner Section", value: "inner_section" },
    { label: "Image / Video", value: "image" },
    // { label: "Menu Item", value: "NavItemComponent" },
    { label: "Media Slider", value: "MediaSlider" },
    { label: "Accordion", value: "Accordion" },
    { label: "Single Product", value: "singleProduct" },
    { label: "Product Slider", value: "ProductSlider" },
    { label: "Navigation Container", value: "navigation-container" },
    { label: "Cart", value: "CartComponent" },
    { label: "Language select", value: "LanguageSelect" },
    { label: "Nav Item", value: "nav-item" },
    // { label: "Content", value: "ContentComponent" },
    //{ label: "Section", value: "section" },

    //{ label: "ProductsService", value: "ProductsServicesComponent" },
    // 'Link',
    // 'NavItemComponent',
  ].sort((a, b) => a.label.localeCompare(b.label));

  if (component && component.parent_id) {
    parentElement = useAppSelector(
      (state) => state.builder.structure.components[component.parent_id!]
    );
  }

  if (parentElement && parentElement.type === "inner_section") {
    // Remove inner_section from elementTypes

    elementTypes.filter((x) => x.value !== "inner_section");

    elementTypes.splice(
      elementTypes.findIndex((x) => x.value === "inner_section"),
      1
    );
  }

  if (component.type === "nav-item") {
    elementTypes.filter((x) => x.value !== "nav-item");
  }

  if (component.type === "navigation-container") {
    // Remove everything from elementTypes except nav-item
    // elementTypes.filter((x) => x.value !== "nav-item");

    // Remove all items from elementTypes except nav-item

    const value = elementTypes.filter((x) => x.value !== "nav-item");

    elementTypes = value;
  }

  if (component.type === "nav") {
    // Remove everything from elementTypes except nav-item
    // elementTypes.filter((x) => x.value !== "nav-item");
  }

  if (component.type === "header") {
    elementTypes.filter((x) => x.value !== "inner_section");

    elementTypes.splice(
      elementTypes.findIndex((x) => x.value === "form"),
      1
    );

    elementTypes.splice(
      elementTypes.findIndex((x) => x.value === "inner_section"),
      1
    );

    elementTypes.splice(
      elementTypes.findIndex((x) => x.value === "ProductDetailComponent"),
      1
    );

    elementTypes.splice(
      elementTypes.findIndex((x) => x.value === "ProductsComponent"),
      1
    );

    elementTypes.splice(
      elementTypes.findIndex((x) => x.value === "ProductsServicesComponent"),
      1
    );

    elementTypes.splice(
      elementTypes.findIndex((x) => x.value === "Checkout"),
      1
    );
  }

  const quickTypes = elementTypes.filter((x) =>
    ["text", "Button", "image"].includes(x.value)
  );

  const observer = new ResizeObserver((entries) => {
    setStyle({ maxWidth: `${entries[0].target.clientWidth}px` });
  });
  useEffect(() => {
    observer.observe(document.getElementById(component.id.toString())!);
  }, []);

  function onTypeClick(type: string) {
    let textAttributes: any[] = [];

    let sortValue;

    if (component.type !== "section") {
      sortValue = component.sort_order + 1;
    } else if (component.childWebpageComponentRelations.length > 0) {
      sortValue = component.childWebpageComponentRelations.length + 1;
    } else {
      sortValue = 1;
    }

    // TODO: Need to check if inner section can be added to an elemenet

    // if (type === "image") {
    //   StructureAPI.addComponent(component.webpage_id, {
    //     type: type,

    //     attributes: [
    //       { name: "src", value: "", type: "attributes" },
    //       { name: "alt", value: "", type: "attributes" },
    //       { name: "styles", value: "", type: "attributes" },
    //     ],
    //     parent_id: component.id,
    //   });

    //   return;
    // }

    if (type === "CartComponent") {
      StructureAPI.addComponent(component.webpage_id, {
        type: "cart",
        attributes: [],
        parent_id: component.id,
        sort_order: component.sort_order + 1,
      });

      return;
    }

    if (type === "navigation-container") {
      StructureAPI.addComponent(component.webpage_id, {
        type: "navigation-container",
        attributes: [
          { name: "paddingLeft", value: "10px", type: "styles" },
          { name: "paddingRight", value: "10px", type: "styles" },
          { name: "paddingTop", value: "10px", type: "styles" },
          { name: "paddingBottom", value: "10px", type: "styles" },
        ],
        parent_id: component.id,
        sort_order: component.sort_order + 1,
      }).then((x) => {
        StructureAPI.addComponent(component.webpage_id, {
          type: "nav-item",
          sort_order: component.sort_order + 1,
          attributes: [
            {
              type: "attributes",
              name: "text",
              value: "Nav link",
            },
            {
              type: "attributes",
              name: "link",
              value: { url: "", type: "None", target: "" },
            },
            {
              type: "attributes",
              name: "typography_id",
              value: typographies.find((x) => x.id === defaultTypography)?.id
                ? typographies.find((x) => x.id === defaultTypography)?.id
                : typographies.find((x: any) => x.tag == "p")?.id ?? null,
            },
          ],
          parent_id: x.payload[Object.keys(x.payload)[0]].id,
        });
      });

      return;
    }

    if (type === "LanguageSelect") {
      StructureAPI.addComponent(component.webpage_id, {
        type: "language-select",
        attributes: [
          {
            type: "attributes",
            name: "dropdown",
            value: "none",
          },
          {
            type: "attributes",
            name: "type",
            value: "image",
          },
          {
            type: "attributes",
            name: "size",
            value: "16px",
          },
        ],
        parent_id: component.id,
        sort_order: component.sort_order + 1,
      });

      return;
    }

    if (type === "singleProduct") {
      StructureAPI.addComponent(component.webpage_id, {
        type: "SingleProductComponent",
        sort_order: component.sort_order + 1,
        attributes: [],
        parent_id: component.id,
      });

      return;
    }

    if (type === "ProductSlider") {
      StructureAPI.addComponent(component.webpage_id, {
        type: "product-slider",
        sort_order: component.sort_order + 1,
        attributes: [],
        parent_id: component.id,
      });

      return;
    }

    if (type === "nav-item") {
      StructureAPI.addComponent(component.webpage_id, {
        type: "nav-item",
        sort_order: component.sort_order + 1,
        attributes: [
          {
            type: "attributes",
            name: "text",
            value: "Nav link",
          },
          {
            type: "attributes",
            name: "link",
            value: { url: "", type: "None", target: "" },
          },
          {
            type: "attributes",
            name: "typography_id",
            value: typographies.find((x) => x.id === defaultTypography)?.id
              ? typographies.find((x) => x.id === defaultTypography)?.id
              : typographies.find((x: any) => x.tag == "p")?.id ?? null,
          },
        ],
        parent_id: component.id,
      });

      return;
    }

    if (type === "Accordion") {
      StructureAPI.addComponent(component.webpage_id, {
        type: type,
        sort_order: sortValue,
        attributes: [
          {
            type: "styles",
            name: "backgroundColor",
            value: "#fafafa",
          },
          {
            type: "styles",
            name: "borderTopWidth",
            value: "1px",
          },
          {
            type: "styles",
            name: "borderBottomWidth",
            value: "1px",
          },
          {
            type: "styles",
            name: "borderLeftWidth",
            value: "1px",
          },
          {
            type: "styles",
            name: "borderRightWidth",
            value: "1px",
          },
          {
            type: "styles",
            name: "borderTopStyle",
            value: "solid",
          },
          {
            type: "styles",
            name: "borderBottomStyle",
            value: "solid",
          },
          {
            type: "styles",
            name: "borderLeftStyle",
            value: "solid",
          },
          { name: "paddingLeft", value: "20px", type: "styles" },
          { name: "paddingRight", value: "20px", type: "styles" },
          { name: "paddingTop", value: "20px", type: "styles" },
          { name: "paddingBottom", value: "20px", type: "styles" },

          {
            type: "styles",
            name: "borderRightStyle",
            value: "solid",
          },
          {
            type: "styles",
            name: "borderTopColor",
            value: "#ededed",
          },
          {
            type: "styles",
            name: "borderBottomColor",
            value: "#ededed",
          },
          {
            type: "styles",
            name: "borderLeftColor",
            value: "#ededed",
          },
          {
            type: "styles",
            name: "borderRightColor",
            value: "#ededed",
          },
        ],
        parent_id: component.id,
      }).then((x: any) => {
        StructureAPI.addComponent(component.webpage_id, {
          type: "section",
          sort_order: 1,
          attributes: [
            { name: "classes", value: "col", type: "attributes" },
            { name: "paddingLeft", value: "0px", type: "styles" },
            { name: "paddingRight", value: "0px", type: "styles" },
            { name: "paddingTop", value: "0px", type: "styles" },
            { name: "paddingBottom", value: "0px", type: "styles" },
            { name: "marginTop", value: "0px", type: "styles" },
            { name: "marginBottom", value: "0px", type: "styles" },
            { name: "marginRight", value: "20px", type: "styles" },
          ],
          parent_id: x.payload[Object.keys(x.payload)[0]].id,
        }).then((x: any) => {
          StructureAPI.addComponent(component.webpage_id, {
            type: "text",
            sort_order: 1,
            attributes: [
              {
                name: "text",
                value: "<span style='fontSize: 24px'>Accordion item</span>",
                type: "attributes",
              },
              {
                type: "attributes",
                name: "typography_id",
                value: typographies.find((x) => x.id === defaultTypography)?.id
                  ? typographies.find((x) => x.id === defaultTypography)?.id
                  : typographies.find((x: any) => x.tag == "p")?.id ?? null,
              },
              { name: "styles", value: "", type: "attributes" },
              { name: "paddingBottom", value: "0px", type: "styles" },
              { name: "paddingTop", value: "0px", type: "styles" },
              { name: "marginTop", value: "0px", type: "styles" },
              { name: "marginBottom", value: "0px", type: "styles" },
            ],
            parent_id: x.payload[Object.keys(x.payload)[0]].id,
          });
        });
      });

      return;
    }

    if (type === "inner_section") {
      StructureAPI.addComponent(component.webpage_id, {
        type: type,
        sort_order: sortValue,
        attributes: [
          { name: "classes", value: "inner-section", type: "attributes" },
          { name: "paddingTop", value: "20px", type: "styles" },
          { name: "paddingLeft", value: "0px", type: "styles" },
          { name: "paddingBottom", value: "20px", type: "styles" },
          { name: "paddingRight", value: "0px", type: "styles" },
          {
            name: "content_width_unit",
            value: website?.content_width_unit,
            type: "attributes",
          },
          {
            name: "content_width_size",
            value: website?.content_width_size,
            type: "attributes",
          },
        ],
        parent_id: component.id,
      }).then((x: any) => {
        StructureAPI.addComponent(component.webpage_id, {
          type: "section",
          sort_order: 1,
          attributes: [
            { name: "classes", value: "col", type: "attributes" },
            { name: "paddingTop", value: "20px", type: "styles" },
            { name: "paddingLeft", value: "20px", type: "styles" },
            { name: "paddingBottom", value: "20px", type: "styles" },
            { name: "paddingRight", value: "20px", type: "styles" },
          ],
          parent_id: x.payload[Object.keys(x.payload)[0]].id,
        });

        deleteComponentById(editorId);
        addNewComponent({
          id: editorId,
          type: "editor",
          sort_order: 1,
          parent_id: x.payload[Object.keys(x.payload)[0]].id,
          childElements: [],
        } as any);
      });

      return;

      // .then((x) => {
      //   deleteComponentById(editorId);
      //   addNewComponent({
      //     id: editorId,
      //     type: "editor",
      //     sort_order: 1,
      //     parent_id: x.payload[Object.keys(x.payload)[0]].id,
      //     childElements: [],
      //   } as any);
      //   //setEditableComponentId(x.payload[Object.keys(x.payload)[0]].id);
      //   setTimeout(() => {
      //     let y = document
      //       .getElementById(
      //         x.payload[Object.keys(x.payload)[0]].id.toString()
      //       )
      //       ?.querySelectorAll("[contenteditable]")[0] as HTMLElement;
      //     if (y) {
      //       let s = window.getSelection();
      //       let r = document.createRange();
      //       r.selectNodeContents(y);
      //       r.collapse(false);
      //       s?.removeAllRanges();
      //       s?.addRange(r);
      //     }
      //   }, 500);
      // });
    }

    if (type === "MediaSlider") {
      StructureAPI.addComponent(component.webpage_id, {
        type: type,
        sort_order: sortValue,
        attributes: [],
        parent_id: component.id,
      }).then((x: any) => {
        deleteComponentById(editorId);
        addNewComponent({
          id: editorId,
          type: "editor",
          sort_order: 1,
          parent_id: x.payload[Object.keys(x.payload)[0]].id,
          childElements: [],
        } as any);
      });

      return;
    }

    if (type === "ContentComponent") {
      StructureAPI.addComponent(component.webpage_id, {
        type: type,
        sort_order: sortValue,
        attributes: [
          {
            type: "attributes",
            name: "text",
            value: "<span style='fontSize: 24px'>Text content</span>",
          },
          {
            type: "attributes",
            name: "typography_id",
            value: typographies.find((x) => x.id === defaultTypography)?.id
              ? typographies.find((x) => x.id === defaultTypography)?.id
              : typographies.find((x: any) => x.tag == "p")?.id ?? null,
          },
        ],
        parent_id: component.id,
      });

      return;
    }

    if (type == "section") {
      StructureAPI.addComponent(component.webpage_id, {
        type: type,
        sort_order: component.sort_order + 1,
        attributes: [],
        parent_id: component.id,
      }).then((x: any) => {
        StructureAPI.addComponent(component.webpage_id, {
          type: "section",
          sort_order: 1,
          attributes: [
            { name: "classes", value: "col", type: "attributes" },
            { name: "paddingTop", value: "0px", type: "styles" },
            { name: "paddingLeft", value: "0px", type: "styles" },
            { name: "paddingBottom", value: "0px", type: "styles" },
            { name: "paddingRight", value: "0px", type: "styles" },
          ],
          parent_id: x.payload[Object.keys(x.payload)[0]].id,
        });
      });
      return;
    }

    if (type == "NavItemComponent") {
      StructureAPI.addComponent(component.webpage_id, {
        type: "nav-item",
        sort_order: component.sort_order + 1,
        attributes: [
          {
            type: "styles",
            name: "marginTop",
            value: "10px",
          },
          {
            type: "attributes",
            name: "text",
            value: "NavItemComponent",
          },
          {
            type: "attributes",
            name: "link",
            value: { url: "", type: "None", target: "" },
          },
        ],
        parent_id: component.id,
      });

      return;
      // type = "nav-item";
      // // {
      // //   type: 'nav-item',
      // //   sort_order: 1,
      // //   attributes: [

      // //   ],
      // // },
      // textAttributes = [
      //   {
      //     type: "attributes",
      //     name: "text",
      //     value: "NavItemComponent",
      //   },
      //   {
      //     type: "attributes",
      //     name: "link",
      //     value: { url: "/", type: "Web", target: "" },
      //   },
      // ];
    } else if (type == "form") {
      // At the moment only 1 form
      StructureAPI.addComponent(component.webpage_id, {
        type: "form",
        sort_order: component.sort_order + 1,
        attributes: [
          {
            type: "styles",
            name: "marginTop",
            value: "10px",
          },
          {
            type: "attributes",
            name: "action",
            value: "/webstoreApi/submitContactForm",
          },
        ],
        parent_id: component.id,
        childElements: [
          {
            type: "text",
            sort_order: 1,
            attributes: [
              {
                type: "attributes",
                name: "text",
                value:
                  '<span style="font-weight: normal;"><font style="font-size: 17px;">Nimi</font></span>',
              },
            ],
          },
          {
            type: "input",
            sort_order: 2,
            attributes: [
              { type: "attributes", name: "placeholder", value: "Teie nimi" },
              { type: "attributes", name: "name", value: "name" },
              { type: "attributes", name: "required", value: true },
            ],
          },
          {
            type: "text",
            sort_order: 3,
            attributes: [
              {
                type: "attributes",
                name: "text",
                value:
                  '<span style="font-weight: normal;"><font style="font-size: 17px;">Email address</font></span>',
              },
              { type: "styles", name: "marginTop", value: "15px" },
            ],
          },
          {
            type: "input",
            sort_order: 4,
            attributes: [
              {
                type: "attributes",
                name: "placeholder",
                value: "E-posti aadress",
              },
              { type: "attributes", name: "name", value: "email" },
              { type: "attributes", name: "required", value: true },
            ],
          },
          {
            type: "text",
            sort_order: 5,
            attributes: [
              {
                type: "attributes",
                name: "text",
                value:
                  '<span style="font-weight: normal;"><font style="font-size: 17px;">Sinu sõnum</font></span>',
              },
              { type: "styles", name: "marginTop", value: "15px" },
            ],
          },
          {
            type: "input",
            sort_order: 6,
            attributes: [
              { type: "attributes", name: "placeholder", value: "" },
              { type: "attributes", name: "text_area", value: true },
              { type: "attributes", name: "name", value: "content" },
              { type: "attributes", name: "required", value: true },
            ],
          },

          {
            type: "input",
            sort_order: 7,
            attributes: [
              { type: "attributes", name: "placeholder", value: "" },
              { type: "attributes", name: "name", value: "hash" },
              { type: "attributes", name: "content", value: "content" },
              { type: "attributes", name: "required", value: true },
              { type: "attributes", name: "hidden", value: true },
              { type: "attributes", name: "id", value: "hashconfirm" },
            ],
          },

          {
            type: "Button",
            sort_order: 8,
            attributes: [
              { type: "attributes", name: "text_area", value: true },
              { type: "attributes", name: "buttonType", value: "submit" },
              {
                type: "attributes",
                name: "text",
                value:
                  '<font style="font-size: 15px;" color="#ffffff">Saada</font>',
              },
              {
                type: "styles",
                name: "backgroundColor",
                value: "rgb(53, 158, 63)",
              },
              { type: "styles", name: "borderBottomWidth", value: "0px" },
              { type: "styles", name: "borderLeftWidth", value: "0px" },
              { type: "styles", name: "borderRightWidth", value: "0px" },
              { type: "styles", name: "borderTopWidth", value: "0px" },
              { type: "styles", name: "marginTop", value: "15px" },
              { type: "styles", name: "paddingTop", value: "10px" },
              { type: "styles", name: "paddingBottom", value: "10px" },
              { type: "styles", name: "paddingRight", value: "15px" },
              { type: "styles", name: "paddingLeft", value: "15px" },
              { type: "styles", name: "width", value: "100%" },

              { type: "styles", name: "display", value: "flex" },
              {
                type: "attributes",
                name: "typography_id",
                value: typographies.find((x) => x.id === defaultTypography)?.id
                  ? typographies.find((x) => x.id === defaultTypography)?.id
                  : typographies.find((x: any) => x.tag == "p")?.id ?? null,
              },
            ],
          },
        ],
      });
      return;
    } else if (type === "button") {
      textAttributes = [
        {
          type: "attributes",
          name: "type",
          value: "button",
        },
        {
          type: "attributes",
          name: "link",
          value: { url: "", type: "Page", target: "_self" },
        },
        {
          type: "attributes",
          name: "text",
          value: '<font color="#fff">Click me</font>',
        },
        {
          type: "attributes",
          name: "typography_id",
          value: typographies.find((x) => x.id === defaultTypography)?.id
            ? typographies.find((x) => x.id === defaultTypography)?.id
            : typographies.find((x: any) => x.tag == "p")?.id ?? null,
        },
        {
          type: "styles",
          name: "marginTop",
          value: "0px",
        },
        {
          type: "styles",
          name: "borderTopWidth",
          value: "0px",
        },
        {
          type: "styles",
          name: "borderBottomWidth",
          value: "0px",
        },
        {
          type: "styles",
          name: "borderLeftWidth",
          value: "0px",
        },
        {
          type: "styles",
          name: "borderRightWidth",
          value: "0px",
        },
        {
          type: "styles",
          name: "borderTopStyle",
          value: "solid",
        },
        {
          type: "styles",
          name: "borderBottomStyle",
          value: "solid",
        },
        {
          type: "styles",
          name: "borderLeftStyle",
          value: "solid",
        },
        {
          type: "styles",
          name: "borderRightStyle",
          value: "solid",
        },
        {
          type: "styles",
          name: "borderTopColor",
          value: "#616161",
        },
        {
          type: "styles",
          name: "borderBottomColor",
          value: "#616161",
        },
        {
          type: "styles",
          name: "borderLeftColor",
          value: "#616161",
        },
        {
          type: "styles",
          name: "borderRightColor",
          value: "#616161",
        },
        {
          type: "styles",
          name: "borderTopLeftRadius",
          value: "6px",
        },
        {
          type: "styles",
          name: "borderTopRightRadius",
          value: "6px",
        },
        {
          type: "styles",
          name: "borderBottomLeftRadius",
          value: "6px",
        },
        {
          type: "styles",
          name: "borderBottomRightRadius",
          value: "6px",
        },
        {
          type: "styles",
          name: "paddingTop",
          value: "0px",
        },
        {
          type: "styles",
          name: "paddingBottom",
          value: "0px",
        },
        {
          type: "styles",
          name: "paddingLeft",
          value: "0px",
        },
        {
          type: "styles",
          name: "display",
          value: "flex",
        },
        {
          type: "styles",
          name: "paddingRight",
          value: "0px",
        },
        {
          type: "styles",
          name: "backgroundColor",
          value: "#616161",
        },
      ];
    } else {
      textAttributes = [
        {
          type: "attributes",
          name: "text",
          value:
            type === "Button"
              ? "<span style='fontSize: 24px'>Button component</span>"
              : "<span style='fontSize: 24px'>Text content</span>",
        },
        {
          type: "attributes",
          name: "typography_id",
          value: typographies.find((x) => x.id === defaultTypography)?.id
            ? typographies.find((x) => x.id === defaultTypography)?.id
            : typographies.find((x: any) => x.tag == "p")?.id ?? null,
        },
        {
          type: "styles",
          name: "paddingTop",
          value: type === "Button" ? "10px" : "0px",
        },
        {
          type: "styles",
          name: "paddingBottom",
          value: type === "Button" ? "10px" : "0px",
        },
        {
          type: "styles",
          name: "paddingLeft",
          value: type === "Button" ? "20px" : "0px",
        },
        {
          type: "styles",
          name: "paddingRight",
          value: type === "Button" ? "20px" : "0px",
        },
        {
          type: "styles",
          name: "marginTop",
          value: "0px",
        },
        {
          type: "styles",
          name: "marginBottom",
          value: "0px",
        },
      ];
    }

    StructureAPI.addComponent(component.webpage_id, {
      type,
      sort_order: sortValue,
      attributes: textAttributes,
      parent_id: component.id,
    }).then((x) => {
      deleteComponentById(editorId);
      addNewComponent({
        id: editorId,
        type: "editor",
        sort_order: 1,
        parent_id: x.payload[Object.keys(x.payload)[0]].id,
        childElements: [],
      } as any);
      //setEditableComponentId(x.payload[Object.keys(x.payload)[0]].id);
      setTimeout(() => {
        let y = document
          .getElementById(x.payload[Object.keys(x.payload)[0]].id.toString())
          ?.querySelectorAll("[contenteditable]")[0] as HTMLElement;
        if (y) {
          let s = window.getSelection();
          let r = document.createRange();
          r.selectNodeContents(y);
          r.collapse(false);
          s?.removeAllRanges();
          s?.addRange(r);
        }
      }, 500);
    });
  }

  return (
    <ul className="m-0 p-0">
      <div className="select-element-type__container" style={style}>
        <div
          className={
            "select-element-type__list" + (expanded ? "" : " flex-nowrap")
          }
        >
          {component.type === "navigation-container" ? (
            <>
              <div
                key={"nav-item"}
                className="select-element-type__list__item"
                onClick={() => onTypeClick("nav-item")}
              >
                Nav item
              </div>
            </>
          ) : (
            <></>
          )}

          {component.type !== "navigation-container" &&
            (expanded ? elementTypes : quickTypes).map((x) => (
              <div
                key={x.value}
                className="select-element-type__list__item"
                onClick={() => onTypeClick(x.value)}
              >
                {x.label}
              </div>
            ))}
        </div>

        {component.type !== "nav" &&
          component.type !== "navigation-container" && (
            <>
              <MoreHorizOutlined
                style={{
                  fontSize: "22.5px",
                  alignSelf: "center",
                  marginLeft: "5px",
                }}
                onClick={() => setExpanded(!expanded)}
              />
            </>
          )}
      </div>
    </ul>
  );
}
