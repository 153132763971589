import React, { useEffect, useState } from "react";

import "./TemplateEditOverlay.scss";
import { useAppSelector } from "../../../../store/hooks";

import { AllComponents } from "../../../_default/interfaces/base";
import StructureAPI from "../../../../store/reducers/builderReducers/structure/structureApi";
import {
  updateActiveEditor,
  updateEditor,
} from "../../../../store/reducers/builderReducers/editor/editorActions";
import { Close, Save } from "@mui/icons-material";
import { setSeconds } from "date-fns";
import { setStructure } from "../../../../store/reducers/builderReducers/structure/structureActions";

declare var defaultComponents: any;

export default function TemplateEditOverlay() {
  const [open, setOpen] = useState(false);

  const editor = useAppSelector((state) => state.builder.editor);
  const pages = useAppSelector((state) => state.builder.pages);
  const webpage_id = useAppSelector((state) => state.builder.pages.active);
  const components = useAppSelector(
    (state) => state.builder.structure.components
  );

  const structure = useAppSelector((state) => state.builder.structure);

  const [component, setComponent] = useState<AllComponents | null>(null);

  // Add event listener to ESC key, if pressed, change the open state to false

  const handleCloseEditor = () => {
    if (
      component &&
      (component.sort_order === -44 || component?.sort_order === 0)
    ) {
      const updatedStructure = {
        ...structure,
        webpageComponents: [
          ...structure.webpageComponents.filter((x) => x.id !== component.id),
        ],
      };

      // StructureAPI.deleteComponent(component.webpage_id, component)
      //   .then((x) => {
      //     StructureAPI.updateWebpageComponents(
      //       webpage_id!,
      //       updatedStructure
      //     ).then((xe) => {
      //       console.log("updated structure response", xe);
      //     });

      //     // StructureAPI.loadWebpageComponents(webpage_id!).then((x) => {
      //     //   console.log(x, "THIS IS X");

      //     //   setStructure(x);
      //     // });

      //     console.log("this is deleteComponent response", x);
      //   })
      //   .catch((x) => {
      //     console.log("this is deleteComponent error", x);
      //   });

      setOpen(false);
      updateEditor({
        ...editor,
        hasEdited: editor.hasEdited,
        template: null,
        component_id: null,
        component_parent_id: null,
      });
      const sidebar = document.querySelector(".sidebar ul");

      if (sidebar) {
        const activeElement = sidebar.querySelector(".active") as HTMLLIElement;
        if (activeElement) {
          activeElement.click();
        }
      }

      //   });
      // });
    } else {
      setOpen(false);
      updateEditor({
        ...editor,
        hasEdited: editor.hasEdited,
        template: null,
        component_id: null,
        component_parent_id: null,
      });

      const sidebar = document.querySelector(".sidebar ul");

      if (sidebar) {
        const activeElement = sidebar.querySelector(".active") as HTMLLIElement;
        if (activeElement) {
          activeElement.click();
        }
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleCloseEditor();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    editor.template,
    component,
    editor.component_id,
    editor.component_parent_id,
  ]);

  useEffect(() => {
    if (editor.template) {
      setOpen(true);

      setComponent(editor.template);
    }
  }, [editor.template]);

  let ComponentToRender;

  if (component && component.type) {
    ComponentToRender = defaultComponents[component.type];

    let renderComponent = components[editor.component_id!];

    console.log(defaultComponents[component.type], "ComponentToRender");
  } else if (editor.component_id && editor.template) {
    // StructureAPI.addComponent(pages.active!, {
    //   component_id: editor.component_id!,
    //   component_parent_id: null,
    //   sort_order: 0,
    // }).then((x) => {
    //   console.log("x", "this is x", x);
    // });
    // Add that component to the structure
  }

  return (
    <div
      id="template_edit_over_lay"
      className="template-edit-overlay"
      style={{
        display: open ? "flex" : "none",
        width: `calc(100% - 440px)`,
        zIndex: 9999,
      }}
    >
      <div
        className="template-edit-overlay__content"
        id="template_edit_over_lay_content"
      >
        {editor.template && component && (
          <ComponentToRender
            key={editor.template.id}
            {...components[editor.template.id]}
          />
        )}
      </div>

      <div className="template-edit-overlay__close" onClick={handleCloseEditor}>
        Exit template view
        <Close style={{ cursor: "pointer", fontSize: "22px" }} />
      </div>
    </div>
  );
}
